export const marks = {
  0: "0",
  1: "500K",
  2: "1M",
  3: "5M",
  4: "10M",
  5: "25M",
  6: "50M",
  7: "100M",
  8: "250M",
  9: "500M",
  10: "1B",
  11: "5B",
  12: "unlimited",
};
export const renvue = [
  {
    value: 0,
    label: 0,
  },
  {
    value: 500,
    label: "500K",
  },
  {
    value: 1000,
    label: "1M",
  },
  {
    value: 5000,
    label: "5M",
  },
  {
    value: 10000,
    label: "10M",
  },
  {
    value: 25000,
    label: "25M",
  },
  {
    value: 50000,
    label: "50M",
  },
  {
    value: 100000,
    label: "100M",
  },
  {
    value: 250000,
    label: "250M",
  },
  {
    value: 500000,
    label: "500M",
  },
  {
    value: 1000000,
    label: "1B",
  },
  {
    value: 5000000,
    label: "5B",
  },
];
export const PriceInThousand = {
  0: 0,
  "500K": 500,
  "1M": 1000,
  "5M": 5000,
  "10M": 10000,
  "25M": 25000,
  "50M": 50000,
  "100M": 100000,
  "250M": 250000,
  "500M": 500000,
  "1B": 1000000,
  "5B": 5000000,
  "10B": 100000,
  "25B": 250000,
  "50B": 500000,
  "100B": 1000000,
  "500B": 5000000,
  unlimited: undefined,
};
export const RenvueList = [
  {
    label: "0-500K",
    value: "0-500",
  },
  {
    label: "500K-1M",
    value: "500-1000",
  },
  {
    label: "1M-5M",
    value: "1000-5000",
  },
  {
    label: "5M-10M",
    value: "5000-10000",
  },
  {
    label: "10M-25M",
    value: "10000-25000",
  },
  {
    label: "25M-50M",
    value: "25000-50000",
  },
  {
    label: "50M-100M",
    value: "50000-100000",
  },
  {
    label: "100M-250M",
    value: "100000-250000",
  },
  {
    label: "250M-500M",
    value: "250000-500000",
  },
  {
    label: "500M-1B",
    value: "500000-1000000",
  },
  {
    label: "1B-5B",
    value: "1000000-5000000",
  },
  {
    label: "5B+",
    value: "5000000-5000000000000",
  },
];
export const EmployeeList = [
  {
    label: "1-9",
    value: "1-9",
  },
  {
    label: "10-24",
    value: "10-24",
  },
  {
    label: "25-49",
    value: "25-49",
  },
  {
    label: "50-99",
    value: "50-99",
  },
  {
    label: "100-249",
    value: "100-249",
  },
  {
    label: "250-499",
    value: "250-499",
  },
  {
    label: "500-999",
    value: "500-999",
  },
  {
    label: "1,000-4,999",
    value: "1000-4999",
  },
  {
    label: "5,000-9,999",
    value: "5000-9999",
  },

  {
    label: "10k-49,999",
    value: "10000-49999",
  },
  {
    label: "50k-100k",
    value: "50000-100000",
  },

  {
    label: "100k+",
    value: "100000-10000000000000",
  },
];
export const findMinMax = (arr) => {
  let numbers = [];
  arr.forEach((element) => {
    numbers = [...numbers, ...element.value.split("-").map((x) => parseInt(x))];
  });
  return {
    min: Math.min(...numbers),
    max: Math.max(...numbers),
  };
};
