import React from "react";
import { Row, Col } from "antd";
import "antd/dist/antd.css";
import "./styles.css";
import UserForm from "../components/users/UserForm";
import RoleList from "../components/users/RoleList";

const EditUser = () => {
	return (
		<div className="site-layout-content">
			<Row>
				<Col md={{ span: 9, offset: 3 }}>
					<div className="heading custom-clr">Edit user for PureB2B</div>
					<UserForm isEditMode={true} isAddMode={false} />
				</Col>
				<Col md={12} style={{ justifyContent: "center", display: "flex" }}>
					<RoleList />
				</Col>
			</Row>
		</div>
	);
};

export default EditUser;
