import CrudService from './crud'
import axios from "../axios-configured";
class UserService extends CrudService {
    constructor() {
        const subUrl = '/users'
        super(subUrl)
        this.subUrl = subUrl
    }
    async resetPwd(id, payload) {
        await axios.put(`${this.subUrl}/resetPwd/${id}`, payload)
    }
    
    

}
export default UserService
