import React from "react";
import {
  Dropdown,
  Menu,
  Input,
  Space,
  Button,
  Tag,
  Popconfirm,
  Modal,
} from "antd";
import { Link } from "react-router-dom";
import {
  deleteProfile,
  deleteUser,
  deleteAudienceManager,
  deleteAudience,
  ShowCampaignModal,
  refreshAudience,
} from "../actions";
import {
  SearchOutlined,
  MoreOutlined,
  RightOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import DataList from "../components/commons/dataList";
import Store from "../store";
import ResetPassword from "../components/users/ResetPassword";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import RefreshIcon from "@material-ui/icons/Refresh";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { IconButton } from "@material-ui/core";
export const processUsers = (payload) => {
  return payload.map((row) => {
    row.createdAt = new Date(row.createdAt).toLocaleString();
    row.updatedAt = new Date(row.updatedAt).toLocaleString();
    return {
      key: row["_id"],
      ...row,
    };
  });
};

export const processProfiles = (payload) => {
  return payload.map((row) => {
    row.createdAt = new Date(row.createdAt).toLocaleString();
    row.updatedAt = new Date(row.updatedAt).toLocaleString();
    return {
      key: row["_id"],
      ...row,
    };
  });
};

export const processAudiences = (payload) => {
  return payload.map((row) => {
    row.createdAt = new Date(row.createdAt).toLocaleString();
    row.updatedAt = new Date(row.updatedAt).toLocaleString();
    return {
      key: row["_id"],
      ...row,
    };
  });
};

export const userColumns = () => {
  const headers = { Email: "email", Name: "name" };
  var return_headers = Object.keys(headers).map((name) => {
    return {
      title: name,
      dataIndex: headers[name],
      key: name,
      filterMultiple: false,
      ...getSearchProps(headers[name]),
    };
  });
  return_headers.push({
    title: "Role",
    dataIndex: "role",
    render: function (_, record) {
      return <div className="text-capitalize">{record.role.name}</div>;
    },
    filterMultiple: false,
    ...getSearchProps(["role", "name"]),
  });

  return_headers.push({
    title: "Actions",
    dataIndex: "actions",
    render: function (_, record) {
      return (
        <div className="action">
          <Link to={`users/${record._id}/view`}>View</Link>
          <Link to={`users/${record._id}/edit`}>Edit</Link>

          <Popconfirm
            title="Are you sure to delete this User?"
            onConfirm={() =>
              Store.dispatch(deleteUser(record._id, Store.getState().token))
            }
            okText="Yes"
            cancelText="No"
          >
            <a>Delete</a>
          </Popconfirm>
          <ResetPassword id={record._id} />
        </div>
      );
    },
  });

  return return_headers;
};

export const profileColumns = () => {
  const return_headers = [
    {
      title: "Name",
      dataIndex: "name",
      key: "Name",
      filterMultiple: false,
      ...getSearchProps("name"),
    },
    {
      title: "Created By",
      dataIndex: ["createdBy", "email"],
      key: "Created By",
      filterMultiple: false,
      ...getSearchProps(["createdBy", "email"]),
    },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      key: "Last Updated",
      filterMultiple: false,
      ...getSearchProps("updatedAt"),
    },
    {
      title: "Associated Audeince",
      dataIndex: "audienceAssociatedCount",
      key: "Associated Audeince",
      sorter: (a, b) => a.audienceAssociatedCount - b.audienceAssociatedCount,
      sortDirections: ["ascend", "descend"],
    },
  ];
  // var return_headers = Object.keys(headers).map(name => {
  //   return {
  //     title: name,
  //     dataIndex: headers[name],
  //     key: name,
  //     filterMultiple: false,
  //     ...getSearchProps(headers[name]),
  //   }
  // })

  return_headers.push({
    title: "Actions",
    dataIndex: "actions",
    render: function (_, record) {
      const menu = (
        <Menu>
          <Menu.Item>
            <Link to={`profiles/${record._id}/edit`}>
              <Button>Edit</Button>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Popconfirm
              title="Are you sure to delete this Profile?"
              onConfirm={() =>
                Store.dispatch(
                  deleteProfile(record._id, Store.getState().token)
                )
              }
              okText="Yes"
              cancelText="No"
            >
              <Button>Delete</Button>
            </Popconfirm>
          </Menu.Item>
        </Menu>
      );
      return (
        <div>
          <Dropdown overlay={menu} placement="bottomLeft" trigger={["click"]}>
            <MoreOutlined />
          </Dropdown>
        </div>
      );
    },
  });
  return return_headers;
};

export const audienceDetailsColumns = () => {
  const headers = [{ key: "rank", text: "Rank" }];
  var return_headers = headers.map((c) => {
    return {
      title: c.text,
      dataIndex: c.key,
      key: c.key,
    };
  });
  return_headers.unshift({
    title: "Account",
    dataIndex: "companyname",
    render: function (_, record) {
      const containsProtocol =
        record.domain.includes("http://") || record.domain.includes("https://");
      return (
        <div>
          <div className="text-capitalize account clr">
            <b>{record.companyname}</b>
          </div>
          <a
            href={`${
              containsProtocol ? record.domain : "http://" + record.domain
            }`}
            className="account"
          >
            {record.domain}
          </a>
        </div>
      );
    },
  });
  return_headers.push({
    title: "Overall Score",
    dataIndex: "overallscore",
    render: function (_, record) {
      return (
        <div>
          <Tag color="#87d068" className="text-capitalize">
            Tier {record.istrending ? 1 : 2}
          </Tag>
        </div>
      );
    },
  });

  return_headers.push({
    title: "Top Trending Intent",
    dataIndex: "totaltopiclist",
    render: function (_, record) {
      return <DataList data={record.totaltopiclist} />;
    },
  });
  // return_headers.push({
  //   title: 'Revenue', dataIndex: 'totaltopiclist',
  //   render: function (_, record) {
  //     return (<>
  //       <div>Raange:{record.revenue.range}</div>
  //       <div>Min: {record.revenue.min}</div>
  //       <div>Max: {record.revenue.max}</div>

  //     </>)
  //   }
  // })
  // return_headers.push({
  //   title: 'Employee', dataIndex: 'totaltopiclist',
  //   render: function (_, record) {
  //     return (<>
  //       <div>Raange:{record.employee.countrange}</div>
  //       <div>Min: {record.employee.min}</div>
  //       <div>Max: {record.employee.max}</div>

  //     </>)
  //   }
  // })

  return_headers.push({
    title: "",
    dataIndex: "_id",
    render: function (_, record) {
      return (
        <Link to="#">
          <RightOutlined />
        </Link>
      );
    },
  });

  return return_headers;
};

export const audienceColumns = () => {
  const headers = [];
  var return_headers = headers.map((c) => {
    return {
      title: c.text,
      dataIndex: c.key,
      key: c.key,
      filterMultiple: false,
      ...getSearchProps(c.key),
    };
  });

  return_headers.unshift({
    title: "Status",
    dataIndex: "status",
    render: function (_, record) {
      return (
        <>
          {record.status !== "completed" &&
            record.status !== "error" &&
            record.status !== "pending" && (
              <span style={{ color: "#33b5e5" }}>Processing...</span>
            )}
          {record.status === "error" && (
            <span style={{ color: "#d9534f" }}>Error</span>
          )}
          {record.status === "pending" && (
            <span style={{ color: "#055697" }}>Pending</span>
          )}
          {record.status === "completed" && (
            <span style={{ color: "#00c851" }}>Completed</span>
          )}
        </>
      );
    },
  });
  return_headers.unshift({
    title: "Audience",
    dataIndex: "name",
    render: function (_, record) {
      let user = JSON.parse(
        window.localStorage.getItem("pureb2b_user") || "{}"
      );
      console.log(user);
      return (
        <div>
          {record.isCompleted ? (
            <Link to={`/audiences/${record._id}`}>
              <b className="custom-clr">{record.name}</b>
            </Link>
          ) : (
            <b className="custom-clr">{record.name}</b>
          )}

          <p style={{ marginBottom: "5px" }} className="font-size-12">
            Profile:{" "}
            <a href="#">{record.profiles.map((p) => p.name).join(" | ")}</a>
            {record.campaignId ? (
              <span className="ml-2">
                <strong>Campaign ID:</strong>
                {record.campaignId}
              </span>
            ) : (
              ""
            )}
          </p>
          <span className="font-size-12">
            Created: {new Date(record.createdAt).toLocaleString()} by{" "}
            <a href="#"> {record.createdBy.email} </a>
          </span>
          <span className="font-size-12" style={{ display: "block" }}>
            Date Refreshed:{" "}
            {record.completedAt
              ? new Date(record.completedAt).toLocaleString()
              : ""}{" "}
            {record.isCompleted  && (
              <Popconfirm
                title="Are you sure to refresh this Audience?"
                onConfirm={() =>
                  Store.dispatch(
                    refreshAudience(record._id, Store.getState().token)
                  )
                }
                okText="Yes"
                cancelText="No"
              >
                <IconButton
                  style={{ padding: "0px 5px", borderRadius: "unset" }}
                >
                  <RefreshIcon fontSize="1.3rem" />
                </IconButton>
              </Popconfirm>
            )}
            {user.role.name === "administrator" && (
              <Popconfirm
                title="Are you sure to delete this Audience?"
                onConfirm={() =>
                  Store.dispatch(
                    deleteAudience(record._id, Store.getState().token)
                  )
                }
                okText="Yes"
                cancelText="No"
              >
                <IconButton
                  style={{ padding: "0px 5px", borderRadius: "unset" }}
                >
                  <DeleteOutlineOutlinedIcon fontSize="1.3rem" />
                </IconButton>
              </Popconfirm>
            )}
            <IconButton
              style={{ padding: "0px 5px", borderRadius: "unset" }}
              onClick={() => Store.dispatch(ShowCampaignModal(record._id))}
            >
              <AssignmentIcon fontSize="1.3rem" />
            </IconButton>
          </span>
        </div>
      );
    },
  });
  const numDiv = [
    { key: "trendingAccountCount", text: "Tier 1 Accounts" },
    { key: "trendingAccountCount", text: "Tier 2 Accounts" },
    { key: "totalRecords", text: "Total Accounts" },
  ];
  numDiv.forEach((item) => {
    return_headers.push({
      title: item.text,
      dataIndex: item.key,
      render: function (_, record) {
        return (
          <div className="text-center">
            {item.text === "Tier 2 Accounts"
              ? (
                  record["totalRecords"] - record["trendingAccountCount"]
                ).toLocaleString()
              : record[item.key].toLocaleString()}
          </div>
        );
      },
    });
  });
  return_headers.push({
    title: "",
    dataIndex: "_id",
    render: function (_, record) {
      return (
        <>
          {record.isCompleted ? (
            <Link to={`/audiences/${record._id}`}>
              <RightOutlined />
            </Link>
          ) : (
            ""
          )}
        </>
      );
    },
  });

  return return_headers;
};

const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
};

const handleReset = (clearFilters) => {
  clearFilters();
};

const getSearchProps = (dataIndex) => {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      try {
        if (Array.isArray(dataIndex)) {
          let data = Object.assign({}, record);
          if (!data) {
            return "";
          }
          dataIndex.forEach((item) => {
            data = data[item];
          });
          return data
            ? data.toString().toLowerCase().includes(value.toLowerCase())
            : "";
        }
        return record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "";
      } catch (error) {
        console.log(error);
        return "";
      }
    },
  };
};
export const AudienceManagerColumns = () => {
  const return_headers = [
    {
      title: "Name",
      dataIndex: "fileName",
      key: "Name",
      filterMultiple: false,
      ...getSearchProps("fileName"),
    },
    {
      title: "Records",
      dataIndex: "totalRecords",
      key: "totalRecords",
      sorter: (a, b) => a.totalRecords - b.totalRecords,
      sortDirections: ["ascend", "descend"],
      render: function (_, record) {
        return (
          <div className="text-center">
            {record.totalRecords.toLocaleString()}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: function (_, record) {
        return (
          <>
            {record.status !== "completed" &&
              record.status !== "error" &&
              record.status !== "pending" && (
                <span style={{ color: "#33b5e5" }}>Processing...</span>
              )}
            {record.status === "error" && (
              <span style={{ color: "#d9534f" }}>Error</span>
            )}
            {record.status === "pending" && (
              <span style={{ color: "#055697" }}>Pending</span>
            )}
            {record.status === "completed" && (
              <span style={{ color: "#00c851" }}>Completed</span>
            )}
          </>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: function (_, record) {
        return <div>{new Date(record.createdAt).toLocaleString()}</div>;
      },
    },
  ];
  return_headers.push({
    title: "Actions",
    dataIndex: "actions",
    render: function (_, record) {
      const menu = (
        <Menu>
          <Menu.Item>
            <Popconfirm
              title="Are you sure to delete this Target Account List (TAL)?"
              onConfirm={() =>
                Store.dispatch(
                  deleteAudienceManager(record._id, Store.getState().token)
                )
              }
              okText="Yes"
              cancelText="No"
            >
              <Button>Delete</Button>
            </Popconfirm>
          </Menu.Item>
        </Menu>
      );
      return (
        <div>
          <Dropdown overlay={menu} placement="bottomLeft" trigger={["click"]}>
            <MoreOutlined />
          </Dropdown>
        </div>
      );
    },
  });
  return return_headers;
};
