
import React, { useEffect, useState } from 'react';
import ProfileFormName from './ProfileFormName';
import ProfileFormInterests from './ProfileFormInterests'
import {
  Row,
  Col,
  Card

} from 'antd';
import { Redirect, useParams } from 'react-router-dom'
import { Steps } from 'antd';
import { useQuery } from 'react-query'
import ProfileService from '../../services/profile'
const profileService = new ProfileService();
const { Step } = Steps;

const ProfileForm = ({ isEditMode = false, ...rest }) => {
  const { id } = useParams();
  const [btnLoading, setBtnLoading] = useState(false)
  const [pageRedirect, setPageRedirect] = useState(false)
  const [state, setState] = useState({
    name: '',
    topics: []
  })

  const [page, setPage] = useState(1);
  const nextPage = () => {
    setPage(page + 1);
  }
  const setValues = (values) => {

    setState({ ...state, ...values })
  }
  const submitForm = async () => {
    setBtnLoading(true)
    try {
      const payload = state;
      await (isEditMode ? profileService.update(id, payload) : profileService.create(payload))
      setPageRedirect(true)
    } catch (error) {
      console.log(error)
    }

    setBtnLoading(false);


  };
  const { data: profileData = null } = useQuery("profile", () => {
    return id ? profileService.get(id) : {}
  }, {
    refetchOnWindowFocus: false,

  });

  useEffect(() => {
    if (profileData) {
      setState({ name: profileData.name, topics: profileData.topics });
    }
  }, [profileData])

  return (
    <>
      {pageRedirect && <Redirect to="/profiles" />}
      <Row>
        <Col span={7}>
          <Card className=" step-card">
            <Steps direction="vertical" current={page - 1} size="small" >
              <Step title="Name Your Profile" />
              <Step title="What's your buyer's interests" />
            </Steps>
          </Card>
          {page === 2 ?


            <Row className='mt-4'>
              <Col span={24}>
                <Card className="step-card">
                  <div style={{ color: "rgb(82 146 224)", fontSize: "14px" }}>
                    Topic Selection Limit:
                    Please note a maximum of 50 topics may be selected for Audience creation.
                    The system will select and process the first 50 topics.
           </div>
                </Card>
              </Col>
            </Row>
            : <></>
          }
        </Col>
        <Col span={1}>

        </Col>
        <Col span={14}>
          <Card className="step-card">
            {page === 1 && <ProfileFormName
              name={state.name}
              setValues={setValues} continueSubmit={nextPage} />}
            {page === 2 && <ProfileFormInterests
              selectedTopics={state.topics}
              isEditMode={isEditMode}
              btnLoading={btnLoading}
              setValues={setValues} handleSubmit={submitForm} />}
          </Card>
        </Col>

      </Row>
    </>
  );

}



export default ProfileForm;

