import { put, takeEvery, all, call } from 'redux-saga/effects';
import {
  getUsersFromServer, getProfilesFromServer,
  getProfileFromServer,
  getLogInUser,
  getAudiencesFromServer, createProfileApi,
  createAudienceApi, createUserApi, serverLogin, getAudiencesDetailsFromServer, getAudienceFilterValues,
  deleteProfileApi, deleteUserApi, deleteAudienceManagerApi, deleteAudienceApi,
  refreshAudienceApi
} from '../api'
import { convertFilterIntoQuery } from '../utils/filter'
import { convertAudienceFilterIntoQuery } from '../utils/audiencefilter'

function* getUsers({ query }) {
  try {
    let filters = query ? Object.assign(query, { email: query.name }) : {}
    const filters_string = JSON.stringify(filters)
    const data = yield call(() => getUsersFromServer(filters_string));
    yield put({ type: "USERS_RECIEVED", data: data });
  } catch (error) {
    yield put({ type: "FETCH_ERROR", error: error });
  }
}

function* loginUser({ email, password }) {
  const formData = { email, password };
  try {
    const data = yield call(() => serverLogin(JSON.stringify(formData)));
    if (data && data.statusCode === 401) {
      yield put({ type: "LOGIN_ERROR", error: 'Incorrect email or password' });
    }
    else {
      yield put({ type: "LOGIN_SUCCESS", token: data.token, user: data.user });
    }

  } catch (error) {
    yield put({ type: "LOGIN_ERROR", error: error });
  }
}

function* getProfiles({ token, query }) {
  try {
    let filters = query ? query : {}
    const filters_string = JSON.stringify(filters)
    const data = yield call(() => getProfilesFromServer(token, filters_string));
    yield put({ type: "PROFILES_RECIEVED", data: data });
  } catch (error) {
    yield put({ type: "FETCH_ERROR", error: error });
  }
}
function* getProfile({ id }) {
  try {

    const data = yield call(() => getProfileFromServer(id));
    yield put({ type: "PROFILE_RECIEVED", data: data });
  } catch (error) {
    yield put({ type: "FETCH_ERROR", error: error });
  }
}

function* getAudiences({ token, query }) {
  try {
    let filters = query ? query : {}
    const filters_string = convertAudienceFilterIntoQuery(filters)
    const data = yield call(() => getAudiencesFromServer(token, filters_string));
    yield put({ type: "AUDIENCES_RECIEVED", data: data });
  } catch (error) {
    yield put({ type: "FETCH_ERROR", error: error });
  }
}

function* getAudiencesDetails({ id, token, filters, page, pageSize }) {
  try {

    const filtersJson = filters ? convertFilterIntoQuery(filters) : '{}'
    const data = yield call(() => getAudiencesDetailsFromServer(id, token, filtersJson, page, pageSize));
    yield put({ type: "AUDIENCE_DETAILS_RECIEVED", data: data });
  } catch (error) {
    yield put({ type: "FETCH_ERROR", error: error });
  }
}

function* getAudiencesFilters({ id, token }) {
  try {
    const data = yield call(() => getAudienceFilterValues(id, token));
    yield put({ type: "AUDIENCE_FILTERS_RECIEVED", data: data });
  } catch (error) {
    yield put({ type: "FETCH_ERROR", error: error });
  }
}

function* createUser({ firstName, lastName, email, role, password }) {
  try {
    const formData = {
      name: firstName + " " + lastName, firstName, lastName, email: email, role: { name: role }, password
    }
    const data = yield call(() => createUserApi(JSON.stringify(formData)));
    yield put({ type: "USER_CREATED", data: data });
  } catch (error) {
    yield put({ type: "FORM_ERROR", error: error });
  }
}

function* deleteAudience({ id, token }) {
  try {
    const data = yield call(() => deleteAudienceApi(id, token));
    yield put({ type: "AUDIENCE_DELETED", data: data });
  } catch (error) {
    yield put({ type: "FORM_ERROR", error: error });
  }
}
function* refreshAudience({ id, token }) {
  try {
    const data = yield call(() => refreshAudienceApi(id, token));
    yield put({ type: "AUDIENCE_REFRESHED", data: data });
  } catch (error) {
    yield put({ type: "FORM_ERROR", error: error });
  }
}
function* deleteProfile({ id, token }) {
  try {
    const data = yield call(() => deleteProfileApi(id, token));
    yield put({ type: "PROFILE_DELETED", data: data });
  } catch (error) {
    yield put({ type: "FORM_ERROR", error: error });
  }
}
function* deleteAudienceManager({ id, token }) {
  try {
    const data = yield call(() => deleteAudienceManagerApi(id, token));
    yield put({ type: "AUDIENCE_MANAGER_DELETED", data: data });
  } catch (error) {
    yield put({ type: "FORM_ERROR", error: error });
  }
}
function* getCurrentUser({ }) {
  try {

    const data = yield call(() => getLogInUser());
    yield put({ type: "GET_CURRENTUSER_RECIEVED", user: data });
  } catch (error) {
    yield put({ type: "FETCH_ERROR", error: error });
  }
}

function* deleteUser({ id, token }) {
  try {
    const data = yield call(() => deleteUserApi(id, token));
    yield put({ type: "USER_DELETED", data: data });
  } catch (error) {
    yield put({ type: "FORM_ERROR", error: error });
  }
}

function* createAudience({ payload, token }) {
  try {
    const formdata = {
      name: payload.name,
      firmographics: payload.firmographics,
      profiles: payload.profiles[0],
      jobFunctions: payload.jobFunctions,
      intentType: payload.intentType,
      targetAccounts: payload.targetAccounts
    }

    const data = yield call(() => createAudienceApi(JSON.stringify(formdata), token));
    yield put({ type: "AUDIENCE_CREATED", data: data });
  } catch (error) {
    yield put({ type: "FORM_ERROR", error: error });
  }
}

function* createProfiles({ name, topics, keywords, token }) {
  try {
    const formdata = { name: name, topics: topics, keywords: keywords }
    const data = yield call(() => createProfileApi(JSON.stringify(formdata), token));
    yield put({ type: "PROFILE_CREATED", data: data });
  } catch (error) {
    yield put({ type: "FORM_ERROR", error: error });
  }
}

function* actionWatcher() {
  yield takeEvery('CREATE_USER', createUser)
  yield takeEvery('GET_USERS', getUsers)
  yield takeEvery('GET_PROFILES', getProfiles)
  yield takeEvery('GET_PROFILE', getProfile)
  yield takeEvery('CREATE_PROFILE', createProfiles)

  yield takeEvery('LOGIN_USER', loginUser)
  yield takeEvery('CREATE_AUDIENCE', createAudience)
  yield takeEvery('GET_AUDIENCES', getAudiences)
  yield takeEvery('GET_AUDIENCE_DETAILS', getAudiencesDetails)
  yield takeEvery('GET_AUDIENCE_FILTER_VALUES', getAudiencesFilters)
  yield takeEvery('DELETE_AUDIENCE', deleteAudience)
  yield takeEvery('REFRESH_AUDIENCE', refreshAudience)
  yield takeEvery('DELETE_PROFILE', deleteProfile)
  yield takeEvery('DELETE_AUDIENCE_MANAGER', deleteAudienceManager)
  yield takeEvery('DELETE_USER', deleteUser)
  yield takeEvery('GET_CURRENTUSER', getCurrentUser)
}

export default function* rootSaga() {
  yield all([
    actionWatcher(),
  ]);
}