import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

import { Layout } from 'antd';
import AppHeader from './AppHeader'
import Nav from './nav'
import { useDispatch, useSelector } from "react-redux";
import { getLogInUser } from '../../actions'
const { Content } = Layout;


const PrivateRoute = ({
  component: Component, auth, ...rest
}) => {
  const { token } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLogInUser())
  }, [])

  return (
    <Route
      {...rest}
      render={props => {
        return (
          <>
            {token ? (
              <>
                <Layout className="layout">
                  <AppHeader />

                  <Nav {...props} />
                  <Content style={{ marginTop: "30px" }}>
                    <Component {...props} />
                  </Content>
                </Layout>

              </>
            ) : <Redirect to="/" />
            }
          </>
        );
      }}
    />
  );
}


export default PrivateRoute;
