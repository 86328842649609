
import React from 'react'
import { Slider } from 'antd';

import { marks } from '../../data/revenue'



function RevenueSlider(props) {
    const { setValue, values } = props

    return (
        <React.Fragment className='sliderToolTip'>
            <Slider
                range
                dots={true}
                max={12}
                min={0}
                tipFormatter={(value) => {
                    return marks[value]
                }
                }
                step={1}
                value={values.revenue && values.revenue.length ? values.revenue : [0, 12]}

                className='custom-clr'
                onChange={(range) => {
                    setValue('revenue', range)

                }
                } />
        </React.Fragment>

    )
}




export default (RevenueSlider);