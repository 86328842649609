const locations = [
  {
    "children": [
      {
        "title": "Anniston, Alabama Area",
        "key": "Anniston, Alabama Area_2"
      },
      {
        "title": "Auburn, Alabama Area",
        "key": "Auburn, Alabama Area_3"
      },
      {
        "title": "Birmingham, Alabama Area",
        "key": "Birmingham, Alabama Area_4"
      },
      {
        "title": "Columbus, Georgia Area",
        "key": "Columbus, Georgia Area_5"
      },
      {
        "title": "Decatur, Alabama Area",
        "key": "Decatur, Alabama Area_6"
      },
      {
        "title": "Dothan, Alabama Area",
        "key": "Dothan, Alabama Area_7"
      },
      {
        "title": "Florence, Alabama Area",
        "key": "Florence, Alabama Area_8"
      },
      {
        "title": "Gadsden, Alabama Area",
        "key": "Gadsden, Alabama Area_9"
      },
      {
        "title": "Huntsville, Alabama Area",
        "key": "Huntsville, Alabama Area_10"
      },
      {
        "title": "Mobile, Alabama Area",
        "key": "Mobile, Alabama Area_11"
      },
      {
        "title": "Montgomery, Alabama Area",
        "key": "Montgomery, Alabama Area_12"
      },
      {
        "title": "Tuscaloosa, Alabama Area",
        "key": "Tuscaloosa, Alabama Area_13"
      }
    ],
    "title": "Alabama",
    "key": "Alabama_1_state"
  },
  {
    "children": [
      {
        "title": "Anchorage, Alaska Area",
        "key": "Anchorage, Alaska Area_15"
      }
    ],
    "title": "Alaska ",
    "key": "Alaska_14_state"
  },
  {
    "children": [
      {
        "title": "Flagstaff, Arizona Area",
        "key": "Flagstaff, Arizona Area_17"
      },
      {
        "title": "Las Vegas, Nevada Area",
        "key": "Las Vegas, Nevada Area_18"
      },
      {
        "title": "Phoenix, Arizona Area",
        "key": "Phoenix, Arizona Area_19"
      },
      {
        "title": "Tucson, Arizona Area",
        "key": "Tucson, Arizona Area_20"
      },
      {
        "title": "Yuma, Arizona Area",
        "key": "Yuma, Arizona Area_21"
      }
    ],
    "title": "Arizona ",
    "key": "Arizona_16_state"
  },
  {
    "children": [
      {
        "title": "Fayetteville, Arkansas Area",
        "key": "Fayetteville, Arkansas Area_23"
      },
      {
        "title": "Fort Smith, Arkansas Area",
        "key": "Fort Smith, Arkansas Area_24"
      },
      {
        "title": "Greater Memphis Area",
        "key": "Greater Memphis Area_25"
      },
      {
        "title": "Jonesboro, Arkansas Area",
        "key": "Jonesboro, Arkansas Area_26"
      },
      {
        "title": "Little Rock, Arkansas Area",
        "key": "Little Rock, Arkansas Area_27"
      },
      {
        "title": "Pine Bluff, Arkansas Area",
        "key": "Pine Bluff, Arkansas Area_28"
      },
      {
        "title": "Texarkana, Texas Area",
        "key": "Texarkana, Texas Area_29"
      }
    ],
    "title": "Arkansas ",
    "key": "Arkansas_22_state"
  },
  {
    "children": [
      {
        "title": "Bakersfield, California Area",
        "key": "Bakersfield, California Area_31"
      },
      {
        "title": "Chico, California Area",
        "key": "Chico, California Area_32"
      },
      {
        "title": "Fresno, California Area",
        "key": "Fresno, California Area_33"
      },
      {
        "title": "Greater Los Angeles Area",
        "key": "Greater Los Angeles Area_34"
      },
      {
        "title": "Greater San Diego Area",
        "key": "Greater San Diego Area_35"
      },
      {
        "title": "Merced, California Area",
        "key": "Merced, California Area_36"
      },
      {
        "title": "Modesto, California Area",
        "key": "Modesto, California Area_37"
      },
      {
        "title": "Orange County, California Area",
        "key": "Orange County, California Area_38"
      },
      {
        "title": "Redding, California Area",
        "key": "Redding, California Area_39"
      },
      {
        "title": "Sacramento, California Area",
        "key": "Sacramento, California Area_40"
      },
      {
        "title": "Salinas, California Area",
        "key": "Salinas, California Area_41"
      },
      {
        "title": "San Francisco Bay Area",
        "key": "San Francisco Bay Area_42"
      },
      {
        "title": "San Luis Obispo, California Area",
        "key": "San Luis Obispo, California Area_43"
      },
      {
        "title": "Santa Barbara, California Area",
        "key": "Santa Barbara, California Area_44"
      },
      {
        "title": "Stockton, California Area",
        "key": "Stockton, California Area_45"
      },
      {
        "title": "Visalia, California Area",
        "key": "Visalia, California Area_46"
      },
      {
        "title": "Yuba City, California Area",
        "key": "Yuba City, California Area_47"
      }
    ],
    "title": "California ",
    "key": "California_30_state"
  },
  {
    "children": [
      {
        "title": "Colorado Springs, Colorado Area",
        "key": "Colorado Springs, Colorado Area_49"
      },
      {
        "title": "Fort Collins, Colorado Area",
        "key": "Fort Collins, Colorado Area_50"
      },
      {
        "title": "Grand Junction, Colorado Area",
        "key": "Grand Junction, Colorado Area_51"
      },
      {
        "title": "Greater Denver Area",
        "key": "Greater Denver Area_52"
      },
      {
        "title": "Pueblo, Colorado Area",
        "key": "Pueblo, Colorado Area_53"
      }
    ],
    "title": "Colorado ",
    "key": "Colorado_48_state"
  },
  {
    "children": [
      {
        "title": "Greater Boston Area",
        "key": "Greater Boston Area_55"
      },
      {
        "title": "Greater New York City Area",
        "key": "Greater New York City Area_56"
      },
      {
        "title": "Hartford, Connecticut Area",
        "key": "Hartford, Connecticut Area_57"
      },
      {
        "title": "New London/Norwich, Connecticut Area",
        "key": "New London/Norwich, Connecticut Area_58"
      }
    ],
    "title": "Connecticut ",
    "key": "Connecticut_54_state"
  },
  {
    "children": [
      {
        "title": "Dover, Delaware Area",
        "key": "Dover, Delaware Area_60"
      },
      {
        "title": "Greater Philadelphia Area",
        "key": "Greater Philadelphia Area_61"
      }
    ],
    "title": "Delaware ",
    "key": "Delaware_59_state"
  },
  {
    "children": [
      {
        "title": "Washington D.C. Metro Area",
        "key": "Washington D.C. Metro Area_63"
      }
    ],
    "title": "District of Columbia ",
    "key": "District of Columbia_62_state"
  },
  {
    "children": [
      {
        "title": "Daytona Beach, Florida Area",
        "key": "Daytona Beach, Florida Area_65"
      },
      {
        "title": "Destin/Fort Walton Beach, Florida Area",
        "key": "Destin/Fort Walton Beach, Florida Area_66"
      },
      {
        "title": "Fort Myers, Florida Area",
        "key": "Fort Myers, Florida Area_67"
      },
      {
        "title": "Fort Pierce, Florida Area",
        "key": "Fort Pierce, Florida Area_68"
      },
      {
        "title": "Gainesville, Florida Area",
        "key": "Gainesville, Florida Area_69"
      },
      {
        "title": "Jacksonville, Florida Area",
        "key": "Jacksonville, Florida Area_70"
      },
      {
        "title": "Lakeland, Florida Area",
        "key": "Lakeland, Florida Area_71"
      },
      {
        "title": "Melbourne, Florida Area",
        "key": "Melbourne, Florida Area_72"
      },
      {
        "title": "Miami/Fort Lauderdale Area",
        "key": "Miami/Fort Lauderdale Area_73"
      },
      {
        "title": "Naples, Florida Area",
        "key": "Naples, Florida Area_74"
      },
      {
        "title": "Ocala, Florida Area",
        "key": "Ocala, Florida Area_75"
      },
      {
        "title": "Orlando, Florida Area",
        "key": "Orlando, Florida Area_76"
      },
      {
        "title": "Panama City, Florida Area",
        "key": "Panama City, Florida Area_77"
      },
      {
        "title": "Pensacola, Florida Area",
        "key": "Pensacola, Florida Area_78"
      },
      {
        "title": "Punta Gorda, Florida Area",
        "key": "Punta Gorda, Florida Area_79"
      },
      {
        "title": "Sarasota, Florida Area",
        "key": "Sarasota, Florida Area_80"
      },
      {
        "title": "Tallahassee, Florida Area",
        "key": "Tallahassee, Florida Area_81"
      },
      {
        "title": "Tampa/St. Petersburg, Florida Area",
        "key": "Tampa/St. Petersburg, Florida Area_82"
      },
      {
        "title": "West Palm Beach, Florida Area",
        "key": "West Palm Beach, Florida Area_83"
      }
    ],
    "title": "Florida ",
    "key": "Florida_64_state"
  },
  {
    "children": [
      {
        "title": "Albany, Georgia Area",
        "key": "Albany, Georgia Area_85"
      },
      {
        "title": "Athens, Georgia Area",
        "key": "Athens, Georgia Area_86"
      },
      {
        "title": "Augusta, Georgia Area",
        "key": "Augusta, Georgia Area_87"
      },
      {
        "title": "Chattanooga, Tennessee Area",
        "key": "Chattanooga, Tennessee Area_88"
      },
      {
        "title": "Columbus, Georgia Area",
        "key": "Columbus, Georgia Area_89"
      },
      {
        "title": "Greater Atlanta Area",
        "key": "Greater Atlanta Area_90"
      },
      {
        "title": "Macon, Georgia Area",
        "key": "Macon, Georgia Area_91"
      },
      {
        "title": "Savannah, Georgia Area",
        "key": "Savannah, Georgia Area_92"
      }
    ],
    "title": "Georgia ",
    "key": "Georgia_84_state"
  },
  {
    "children": [
      {
        "title": "Hawaiian Islands",
        "key": "Hawaiian Islands_94"
      }
    ],
    "title": "Hawaii ",
    "key": "Hawaii_93_state"
  },
  {
    "children": [
      {
        "title": "Boise, Idaho Area",
        "key": "Boise, Idaho Area_96"
      },
      {
        "title": "Pocatello, Idaho Area",
        "key": "Pocatello, Idaho Area_97"
      }
    ],
    "title": "Idaho ",
    "key": "Idaho_95_state"
  },
  {
    "children": [
      {
        "title": "Davenport, Iowa Area",
        "key": "Davenport, Iowa Area_99"
      },
      {
        "title": "Decatur, Illinois Area",
        "key": "Decatur, Illinois Area_100"
      },
      {
        "title": "Greater Chicago Area",
        "key": "Greater Chicago Area_101"
      },
      {
        "title": "Greater St. Louis Area",
        "key": "Greater St. Louis Area_102"
      },
      {
        "title": "Peoria, Illinois Area",
        "key": "Peoria, Illinois Area_103"
      },
      {
        "title": "Rockford, Illinois Area",
        "key": "Rockford, Illinois Area_104"
      },
      {
        "title": "Springfield, Illinois Area",
        "key": "Springfield, Illinois Area_105"
      },
      {
        "title": "Urbana-Champaign, Illinois Area",
        "key": "Urbana-Champaign, Illinois Area_106"
      }
    ],
    "title": "Illinois ",
    "key": "Illinois_98_state"
  },
  {
    "children": [
      {
        "title": "Bloomington, Indiana Area",
        "key": "Bloomington, Indiana Area_108"
      },
      {
        "title": "Cincinnati Area",
        "key": "Cincinnati Area_109"
      },
      {
        "title": "Elkhart, Indiana Area",
        "key": "Elkhart, Indiana Area_110"
      },
      {
        "title": "Evansville, Indiana Area",
        "key": "Evansville, Indiana Area_111"
      },
      {
        "title": "Fort Wayne, Indiana Area",
        "key": "Fort Wayne, Indiana Area_112"
      },
      {
        "title": "Greater Chicago Area",
        "key": "Greater Chicago Area_113"
      },
      {
        "title": "Indianapolis, Indiana Area",
        "key": "Indianapolis, Indiana Area_114"
      },
      {
        "title": "Kokomo, Indiana Area",
        "key": "Kokomo, Indiana Area_115"
      },
      {
        "title": "Lafayette, Indiana Area",
        "key": "Lafayette, Indiana Area_116"
      },
      {
        "title": "Louisville, Kentucky Area",
        "key": "Louisville, Kentucky Area_117"
      },
      {
        "title": "Muncie, Indiana Area",
        "key": "Muncie, Indiana Area_118"
      },
      {
        "title": "South Bend, Indiana Area",
        "key": "South Bend, Indiana Area_119"
      },
      {
        "title": "Terre Haute, Indiana Area",
        "key": "Terre Haute, Indiana Area_120"
      }
    ],
    "title": "Indiana ",
    "key": "Indiana_107_state"
  },
  {
    "children": [
      {
        "title": "Cedar Rapids, Iowa Area",
        "key": "Cedar Rapids, Iowa Area_122"
      },
      {
        "title": "Davenport, Iowa Area",
        "key": "Davenport, Iowa Area_123"
      },
      {
        "title": "Des Moines, Iowa Area",
        "key": "Des Moines, Iowa Area_124"
      },
      {
        "title": "Dubuque, Iowa Area",
        "key": "Dubuque, Iowa Area_125"
      },
      {
        "title": "Greater Omaha Area",
        "key": "Greater Omaha Area_126"
      },
      {
        "title": "Iowa City, Iowa Area",
        "key": "Iowa City, Iowa Area_127"
      },
      {
        "title": "Sioux City, Iowa Area",
        "key": "Sioux City, Iowa Area_128"
      },
      {
        "title": "Waterloo, Iowa Area",
        "key": "Waterloo, Iowa Area_129"
      }
    ],
    "title": "Iowa ",
    "key": "Iowa_121_state"
  },
  {
    "children": [
      {
        "title": "Kansas City, Missouri Area",
        "key": "Kansas City, Missouri Area_131"
      },
      {
        "title": "Lawrence, Kansas Area",
        "key": "Lawrence, Kansas Area_132"
      },
      {
        "title": "Topeka, Kansas Area",
        "key": "Topeka, Kansas Area_133"
      },
      {
        "title": "Wichita, Kansas Area",
        "key": "Wichita, Kansas Area_134"
      }
    ],
    "title": "Kansas ",
    "key": "Kansas_130_state"
  },
  {
    "children": [
      {
        "title": "Cincinnati Area",
        "key": "Cincinnati Area_136"
      },
      {
        "title": "Clarksville, Tennessee Area",
        "key": "Clarksville, Tennessee Area_137"
      },
      {
        "title": "Evansville, Indiana Area",
        "key": "Evansville, Indiana Area_138"
      },
      {
        "title": "Huntington, West Virginia Area",
        "key": "Huntington, West Virginia Area_139"
      },
      {
        "title": "Lexington, Kentucky Area",
        "key": "Lexington, Kentucky Area_140"
      },
      {
        "title": "Louisville, Kentucky Area",
        "key": "Louisville, Kentucky Area_141"
      },
      {
        "title": "Owensboro, Kentucky Area",
        "key": "Owensboro, Kentucky Area_142"
      }
    ],
    "title": "Kentucky ",
    "key": "Kentucky_135_state"
  },
  {
    "children": [
      {
        "title": "Alexandria, Louisiana Area",
        "key": "Alexandria, Louisiana Area_144"
      },
      {
        "title": "Baton Rouge, Louisiana Area",
        "key": "Baton Rouge, Louisiana Area_145"
      },
      {
        "title": "Greater New Orleans Area",
        "key": "Greater New Orleans Area_146"
      },
      {
        "title": "Houma, Louisiana Area",
        "key": "Houma, Louisiana Area_147"
      },
      {
        "title": "Lafayette, Louisiana Area",
        "key": "Lafayette, Louisiana Area_148"
      },
      {
        "title": "Lake Charles, Louisiana Area",
        "key": "Lake Charles, Louisiana Area_149"
      },
      {
        "title": "Monroe, Louisiana Area",
        "key": "Monroe, Louisiana Area_150"
      },
      {
        "title": "Shreveport, Louisiana Area",
        "key": "Shreveport, Louisiana Area_151"
      }
    ],
    "title": "Louisiana ",
    "key": "Louisiana_143_state"
  },
  {
    "children": [
      {
        "title": "Bangor, Maine Area",
        "key": "Bangor, Maine Area_153"
      },
      {
        "title": "Greater Boston Area",
        "key": "Greater Boston Area_154"
      },
      {
        "title": "Lewiston/Auburn, Maine Area",
        "key": "Lewiston/Auburn, Maine Area_155"
      },
      {
        "title": "Portland, Maine Area",
        "key": "Portland, Maine Area_156"
      }
    ],
    "title": "Maine ",
    "key": "Maine_152_state"
  },
  {
    "children": [
      {
        "title": "Baltimore, Maryland Area",
        "key": "Baltimore, Maryland Area_158"
      },
      {
        "title": "Cumberland, Maryland Area",
        "key": "Cumberland, Maryland Area_159"
      },
      {
        "title": "Greater Philadelphia Area",
        "key": "Greater Philadelphia Area_160"
      },
      {
        "title": "Washington D.C. Metro Area",
        "key": "Washington D.C. Metro Area_161"
      }
    ],
    "title": "Maryland ",
    "key": "Maryland_157_state"
  },
  {
    "children": [
      {
        "title": "Barnstable/Yarmouth, Massachusetts Area",
        "key": "Barnstable/Yarmouth, Massachusetts Area_163"
      },
      {
        "title": "Greater Boston Area",
        "key": "Greater Boston Area_164"
      },
      {
        "title": "Pittsfield, Massachusetts Area",
        "key": "Pittsfield, Massachusetts Area_165"
      },
      {
        "title": "Providence, Rhode Island Area",
        "key": "Providence, Rhode Island Area_166"
      },
      {
        "title": "Springfield, Massachusetts Area",
        "key": "Springfield, Massachusetts Area_167"
      }
    ],
    "title": "Massachusetts ",
    "key": "Massachusetts_162_state"
  },
  {
    "children": [
      {
        "title": "Benton Harbor, Michigan Area",
        "key": "Benton Harbor, Michigan Area_169"
      },
      {
        "title": "Greater Detroit Area",
        "key": "Greater Detroit Area_170"
      },
      {
        "title": "Greater Grand Rapids, Michigan Area",
        "key": "Greater Grand Rapids, Michigan Area_171"
      },
      {
        "title": "Jackson, Michigan Area",
        "key": "Jackson, Michigan Area_172"
      },
      {
        "title": "Kalamazoo, Michigan Area",
        "key": "Kalamazoo, Michigan Area_173"
      },
      {
        "title": "Lansing, Michigan Area",
        "key": "Lansing, Michigan Area_174"
      },
      {
        "title": "Saginaw, Michigan Area",
        "key": "Saginaw, Michigan Area_175"
      }
    ],
    "title": "Michigan ",
    "key": "Michigan_168_state"
  },
  {
    "children": [
      {
        "title": "Duluth, Minnesota Area",
        "key": "Duluth, Minnesota Area_177"
      },
      {
        "title": "Fargo, North Dakota Area",
        "key": "Fargo, North Dakota Area_178"
      },
      {
        "title": "Grand Forks, North Dakota Area",
        "key": "Grand Forks, North Dakota Area_179"
      },
      {
        "title": "Greater Minneapolis-St. Paul Area",
        "key": "Greater Minneapolis-St. Paul Area_180"
      },
      {
        "title": "La Crosse, Wisconsin Area",
        "key": "La Crosse, Wisconsin Area_181"
      },
      {
        "title": "Rochester, Minnesota Area",
        "key": "Rochester, Minnesota Area_182"
      },
      {
        "title": "St. Cloud, Minnesota Area",
        "key": "St. Cloud, Minnesota Area_183"
      }
    ],
    "title": "Minnesota ",
    "key": "Minnesota_176_state"
  },
  {
    "children": [
      {
        "title": "Biloxi, Mississippi Area",
        "key": "Biloxi, Mississippi Area_185"
      },
      {
        "title": "Greater Memphis Area",
        "key": "Greater Memphis Area_186"
      },
      {
        "title": "Hattiesburg, Mississippi Area",
        "key": "Hattiesburg, Mississippi Area_187"
      },
      {
        "title": "Jackson, Mississippi Area",
        "key": "Jackson, Mississippi Area_188"
      }
    ],
    "title": "Mississippi ",
    "key": "Mississippi_184_state"
  },
  {
    "children": [
      {
        "title": "Columbia, Missouri Area",
        "key": "Columbia, Missouri Area_190"
      },
      {
        "title": "Greater St. Louis Area",
        "key": "Greater St. Louis Area_191"
      },
      {
        "title": "Joplin, Missouri Area",
        "key": "Joplin, Missouri Area_192"
      },
      {
        "title": "Kansas City, Missouri Area",
        "key": "Kansas City, Missouri Area_193"
      },
      {
        "title": "Springfield, Missouri Area",
        "key": "Springfield, Missouri Area_194"
      },
      {
        "title": "St. Joseph, Missouri Area",
        "key": "St. Joseph, Missouri Area_195"
      }
    ],
    "title": "Missouri ",
    "key": "Missouri_189_state"
  },
  {
    "children": [
      {
        "title": "Billings, Montana Area",
        "key": "Billings, Montana Area_197"
      },
      {
        "title": "Great Falls, Montana Area",
        "key": "Great Falls, Montana Area_198"
      },
      {
        "title": "Missoula, Montana Area",
        "key": "Missoula, Montana Area_199"
      }
    ],
    "title": "Montana ",
    "key": "Montana_196_state"
  },
  {
    "children": [
      {
        "title": "Greater Omaha Area",
        "key": "Greater Omaha Area_201"
      },
      {
        "title": "Lincoln, Nebraska Area",
        "key": "Lincoln, Nebraska Area_202"
      },
      {
        "title": "Sioux City, Iowa Area",
        "key": "Sioux City, Iowa Area_203"
      }
    ],
    "title": "Nebraska ",
    "key": "Nebraska_200_state"
  },
  {
    "children": [
      {
        "title": "Las Vegas, Nevada Area",
        "key": "Las Vegas, Nevada Area_205"
      },
      {
        "title": "Reno, Nevada Area",
        "key": "Reno, Nevada Area_206"
      }
    ],
    "title": "Nevada ",
    "key": "Nevada_204_state"
  },
  {
    "children": [
      {
        "title": "Greater Boston Area",
        "key": "Greater Boston Area_208"
      }
    ],
    "title": "New Hampshire ",
    "key": "New Hampshire_207_state"
  },
  {
    "children": [
      {
        "title": "Cheyenne, Wyoming Area",
        "key": "Cheyenne, Wyoming Area_210"
      },
      {
        "title": "Greater New York City Area",
        "key": "Greater New York City Area_211"
      },
      {
        "title": "Greater Philadelphia Area",
        "key": "Greater Philadelphia Area_212"
      }
    ],
    "title": "New Jersey ",
    "key": "New Jersey_209_state"
  },
  {
    "children": [
      {
        "title": "Albuquerque, New Mexico Area",
        "key": "Albuquerque, New Mexico Area_214"
      },
      {
        "title": "Las Cruces, New Mexico Area",
        "key": "Las Cruces, New Mexico Area_215"
      },
      {
        "title": "Santa Fe, New Mexico Area",
        "key": "Santa Fe, New Mexico Area_216"
      }
    ],
    "title": "New Mexico ",
    "key": "New Mexico_213_state"
  },
  {
    "children": [
      {
        "title": "Albany, New York Area",
        "key": "Albany, New York Area_218"
      },
      {
        "title": "Buffalo/Niagara, New York Area",
        "key": "Buffalo/Niagara, New York Area_219"
      },
      {
        "title": "Elmira, New York Area",
        "key": "Elmira, New York Area_220"
      },
      {
        "title": "Ithaca, New York Area",
        "key": "Ithaca, New York Area_221"
      },
      {
        "title": "Glens Falls, New York Area",
        "key": "Glens Falls, New York Area_222"
      },
      {
        "title": "Greater New York City Area",
        "key": "Greater New York City Area_223"
      },
      {
        "title": "Jamestown, New York Area",
        "key": "Jamestown, New York Area_224"
      },
      {
        "title": "Rochester, New York Area",
        "key": "Rochester, New York Area_225"
      },
      {
        "title": "Syracuse, New York Area",
        "key": "Syracuse, New York Area_226"
      },
      {
        "title": "Utica, New York Area",
        "key": "Utica, New York Area_227"
      }
    ],
    "title": "New York ",
    "key": "New York_217_state"
  },
  {
    "children": [
      {
        "title": "Asheville, North Carolina Area",
        "key": "Asheville, North Carolina Area_229"
      },
      {
        "title": "Charlotte, North Carolina Area",
        "key": "Charlotte, North Carolina Area_230"
      },
      {
        "title": "Fayetteville, North Carolina Area",
        "key": "Fayetteville, North Carolina Area_231"
      },
      {
        "title": "Goldsboro, North Carolina Area",
        "key": "Goldsboro, North Carolina Area_232"
      },
      {
        "title": "Greensboro/Winston-Salem, North Carolina Area",
        "key": "Greensboro/Winston-Salem, North Carolina Area_233"
      },
      {
        "title": "Greenville, North Carolina Area",
        "key": "Greenville, North Carolina Area_234"
      },
      {
        "title": "Hickory/Lenoir, North Carolina Area",
        "key": "Hickory/Lenoir, North Carolina Area_235"
      },
      {
        "title": "Jacksonville, North Carolina Area",
        "key": "Jacksonville, North Carolina Area_236"
      },
      {
        "title": "Norfolk, Virginia Area",
        "key": "Norfolk, Virginia Area_237"
      },
      {
        "title": "Raleigh-Durham, North Carolina Area",
        "key": "Raleigh-Durham, North Carolina Area_238"
      },
      {
        "title": "Rocky Mount, North Carolina Area",
        "key": "Rocky Mount, North Carolina Area_239"
      },
      {
        "title": "Wilmington, North Carolina Area",
        "key": "Wilmington, North Carolina Area_240"
      }
    ],
    "title": "North Carolina ",
    "key": "North Carolina_228_state"
  },
  {
    "children": [
      {
        "title": "Bismarck, North Dakota Area",
        "key": "Bismarck, North Dakota Area_242"
      },
      {
        "title": "Fargo, North Dakota Area",
        "key": "Fargo, North Dakota Area_243"
      },
      {
        "title": "Grand Forks, North Dakota Area",
        "key": "Grand Forks, North Dakota Area_244"
      }
    ],
    "title": "North Dakota ",
    "key": "North Dakota_241_state"
  },
  {
    "children": [
      {
        "title": "Canton, Ohio Area",
        "key": "Canton, Ohio Area_246"
      },
      {
        "title": "Cincinnati Area",
        "key": "Cincinnati Area_247"
      },
      {
        "title": "Cleveland/Akron, Ohio Area",
        "key": "Cleveland/Akron, Ohio Area_248"
      },
      {
        "title": "Columbus, Ohio Area",
        "key": "Columbus, Ohio Area_249"
      },
      {
        "title": "Dayton, Ohio Area",
        "key": "Dayton, Ohio Area_250"
      },
      {
        "title": "Huntington, West Virginia Area",
        "key": "Huntington, West Virginia Area_251"
      },
      {
        "title": "Lima, Ohio Area",
        "key": "Lima, Ohio Area_252"
      },
      {
        "title": "Mansfield, Ohio Area",
        "key": "Mansfield, Ohio Area_253"
      },
      {
        "title": "Parkersburg, West Virginia Area",
        "key": "Parkersburg, West Virginia Area_254"
      },
      {
        "title": "Steubenville, Ohio Area",
        "key": "Steubenville, Ohio Area_255"
      },
      {
        "title": "Toledo, Ohio Area",
        "key": "Toledo, Ohio Area_256"
      },
      {
        "title": "Wheeling, West Virginia Area",
        "key": "Wheeling, West Virginia Area_257"
      },
      {
        "title": "Youngstown, Ohio Area",
        "key": "Youngstown, Ohio Area_258"
      }
    ],
    "title": "Ohio ",
    "key": "Ohio_245_state"
  },
  {
    "children": [
      {
        "title": "Enid, Oklahoma Area",
        "key": "Enid, Oklahoma Area_260"
      },
      {
        "title": "Fort Smith, Arkansas Area",
        "key": "Fort Smith, Arkansas Area_261"
      },
      {
        "title": "Lawton, Oklahoma Area",
        "key": "Lawton, Oklahoma Area_262"
      },
      {
        "title": "Oklahoma City, Oklahoma Area",
        "key": "Oklahoma City, Oklahoma Area_263"
      },
      {
        "title": "Tulsa, Oklahoma Area",
        "key": "Tulsa, Oklahoma Area_264"
      }
    ],
    "title": "Oklahoma ",
    "key": "Oklahoma_259_state"
  },
  {
    "children": [
      {
        "title": "Corvallis, Oregon Area",
        "key": "Corvallis, Oregon Area_266"
      },
      {
        "title": "Eugene, Oregon Area",
        "key": "Eugene, Oregon Area_267"
      },
      {
        "title": "Medford, Oregon Area",
        "key": "Medford, Oregon Area_268"
      },
      {
        "title": "Portland, Oregon Area",
        "key": "Portland, Oregon Area_269"
      }
    ],
    "title": "Oregon ",
    "key": "Oregon_265_state"
  },
  {
    "children": [
      {
        "title": "Allentown, Pennsylvania Area",
        "key": "Allentown, Pennsylvania Area_271"
      },
      {
        "title": "Altoona, Pennsylvania Area",
        "key": "Altoona, Pennsylvania Area_272"
      },
      {
        "title": "Erie, Pennsylvania Area",
        "key": "Erie, Pennsylvania Area_273"
      },
      {
        "title": "Greater Philadelphia Area",
        "key": "Greater Philadelphia Area_274"
      },
      {
        "title": "Greater Pittsburgh Area",
        "key": "Greater Pittsburgh Area_275"
      },
      {
        "title": "Harrisburg, Pennsylvania Area",
        "key": "Harrisburg, Pennsylvania Area_276"
      },
      {
        "title": "Johnstown, Pennsylvania Area",
        "key": "Johnstown, Pennsylvania Area_277"
      },
      {
        "title": "Lancaster, Pennsylvania Area",
        "key": "Lancaster, Pennsylvania Area_278"
      },
      {
        "title": "Reading, Pennsylvania Area",
        "key": "Reading, Pennsylvania Area_279"
      },
      {
        "title": "Scranton, Pennsylvania Area",
        "key": "Scranton, Pennsylvania Area_280"
      },
      {
        "title": "Sharon, Pennsylvania Area",
        "key": "Sharon, Pennsylvania Area_281"
      },
      {
        "title": "State College, Pennsylvania Area",
        "key": "State College, Pennsylvania Area_282"
      },
      {
        "title": "Williamsport, Pennsylvania Area",
        "key": "Williamsport, Pennsylvania Area_283"
      },
      {
        "title": "York, Pennsylvania Area",
        "key": "York, Pennsylvania Area_284"
      }
    ],
    "title": "Pennsylvania ",
    "key": "Pennsylvania_270_state"
  },
  {
    "children": [
      {
        "title": "New London/Norwich, Connecticut Area",
        "key": "New London/Norwich, Connecticut Area_286"
      },
      {
        "title": "Providence, Rhode Island Area",
        "key": "Providence, Rhode Island Area_287"
      }
    ],
    "title": "Rhode Island ",
    "key": "Rhode Island_285_state"
  },
  {
    "children": [
      {
        "title": "Augusta, Georgia Area",
        "key": "Augusta, Georgia Area_289"
      },
      {
        "title": "Charleston, South Carolina Area",
        "key": "Charleston, South Carolina Area_290"
      },
      {
        "title": "Charlotte, North Carolina Area",
        "key": "Charlotte, North Carolina Area_291"
      },
      {
        "title": "Columbia, South Carolina Area",
        "key": "Columbia, South Carolina Area_292"
      },
      {
        "title": "Florence, South Carolina Area",
        "key": "Florence, South Carolina Area_293"
      },
      {
        "title": "Greenville, South Carolina Area",
        "key": "Greenville, South Carolina Area_294"
      },
      {
        "title": "Myrtle Beach, South Carolina Area",
        "key": "Myrtle Beach, South Carolina Area_295"
      },
      {
        "title": "Sumter, South Carolina Area",
        "key": "Sumter, South Carolina Area_296"
      }
    ],
    "title": "South Carolina ",
    "key": "South Carolina_288_state"
  },
  {
    "children": [
      {
        "title": "Rapid City, South Dakota Area",
        "key": "Rapid City, South Dakota Area_298"
      },
      {
        "title": "Sioux Falls, South Dakota Area",
        "key": "Sioux Falls, South Dakota Area_299"
      }
    ],
    "title": "South Dakota ",
    "key": "South Dakota_297_state"
  },
  {
    "children": [
      {
        "title": "Chattanooga, Tennessee Area",
        "key": "Chattanooga, Tennessee Area_301"
      },
      {
        "title": "Clarksville, Tennessee Area",
        "key": "Clarksville, Tennessee Area_302"
      },
      {
        "title": "Greater Memphis Area",
        "key": "Greater Memphis Area_303"
      },
      {
        "title": "Greater Nashville Area",
        "key": "Greater Nashville Area_304"
      },
      {
        "title": "Jackson, Tennessee Area",
        "key": "Jackson, Tennessee Area_305"
      },
      {
        "title": "Johnson City, Tennessee Area",
        "key": "Johnson City, Tennessee Area_306"
      },
      {
        "title": "Knoxville, Tennessee Area",
        "key": "Knoxville, Tennessee Area_307"
      }
    ],
    "title": "Tennessee ",
    "key": "Tennessee_300_state"
  },
  {
    "children": [
      {
        "title": "Abilene, Texas Area",
        "key": "Abilene, Texas Area_309"
      },
      {
        "title": "Amarillo, Texas Area",
        "key": "Amarillo, Texas Area_310"
      },
      {
        "title": "Austin, Texas Area",
        "key": "Austin, Texas Area_311"
      },
      {
        "title": "Beaumont/Port Arthur, Texas Area",
        "key": "Beaumont/Port Arthur, Texas Area_312"
      },
      {
        "title": "Brownsville, Texas Area",
        "key": "Brownsville, Texas Area_313"
      },
      {
        "title": "Bryan/College Station, Texas Area",
        "key": "Bryan/College Station, Texas Area_314"
      },
      {
        "title": "Corpus Christi, Texas Area",
        "key": "Corpus Christi, Texas Area_315"
      },
      {
        "title": "Dallas/Fort Worth Area",
        "key": "Dallas/Fort Worth Area_316"
      },
      {
        "title": "El Paso, Texas Area",
        "key": "El Paso, Texas Area_317"
      },
      {
        "title": "Houston, Texas Area",
        "key": "Houston, Texas Area_318"
      },
      {
        "title": "Killeen/Temple, Texas Area",
        "key": "Killeen/Temple, Texas Area_319"
      },
      {
        "title": "Laredo, Texas Area",
        "key": "Laredo, Texas Area_320"
      },
      {
        "title": "Longview, Texas Area",
        "key": "Longview, Texas Area_321"
      },
      {
        "title": "Lubbock, Texas Area",
        "key": "Lubbock, Texas Area_322"
      },
      {
        "title": "Mcallen, Texas Area",
        "key": "Mcallen, Texas Area_323"
      },
      {
        "title": "Odessa/Midland, Texas Area",
        "key": "Odessa/Midland, Texas Area_324"
      },
      {
        "title": "San Angelo, Texas Area",
        "key": "San Angelo, Texas Area_325"
      },
      {
        "title": "San Antonio, Texas Area",
        "key": "San Antonio, Texas Area_326"
      },
      {
        "title": "Sherman/Denison, Texas Area",
        "key": "Sherman/Denison, Texas Area_327"
      },
      {
        "title": "Texarkana, Texas Area",
        "key": "Texarkana, Texas Area_328"
      },
      {
        "title": "Tyler, Texas Area",
        "key": "Tyler, Texas Area_329"
      },
      {
        "title": "Victoria, Texas Area",
        "key": "Victoria, Texas Area_330"
      },
      {
        "title": "Waco, Texas Area",
        "key": "Waco, Texas Area_331"
      },
      {
        "title": "Wichita Falls, Texas Area",
        "key": "Wichita Falls, Texas Area_332"
      }
    ],
    "title": "Texas ",
    "key": "Texas_308_state"
  },
  {
    "children": [
      {
        "title": "Flagstaff, Arizona Area",
        "key": "Flagstaff, Arizona Area_334"
      },
      {
        "title": "Greater Salt Lake City Area",
        "key": "Greater Salt Lake City Area_335"
      },
      {
        "title": "Provo, Utah Area",
        "key": "Provo, Utah Area_336"
      }
    ],
    "title": "Utah ",
    "key": "Utah_333_state"
  },
  {
    "children": [
      {
        "title": "Burlington, Vermont Area",
        "key": "Burlington, Vermont Area_338"
      }
    ],
    "title": "Vermont ",
    "key": "Vermont_337_state"
  },
  {
    "children": [
      {
        "title": "Charlottesville, Virginia Area",
        "key": "Charlottesville, Virginia Area_340"
      },
      {
        "title": "Danville, Virginia Area",
        "key": "Danville, Virginia Area_341"
      },
      {
        "title": "Johnson City, Tennessee Area",
        "key": "Johnson City, Tennessee Area_342"
      },
      {
        "title": "Lynchburg, Virginia Area",
        "key": "Lynchburg, Virginia Area_343"
      },
      {
        "title": "Norfolk, Virginia Area",
        "key": "Norfolk, Virginia Area_344"
      },
      {
        "title": "Richmond, Virginia Area",
        "key": "Richmond, Virginia Area_345"
      },
      {
        "title": "Roanoke, Virginia Area",
        "key": "Roanoke, Virginia Area_346"
      },
      {
        "title": "Washington D.C. Metro Area",
        "key": "Washington D.C. Metro Area_347"
      }
    ],
    "title": "Virginia ",
    "key": "Virginia_339_state"
  },
  {
    "children": [
      {
        "title": "Bellingham, Washington Area",
        "key": "Bellingham, Washington Area_349"
      },
      {
        "title": "Greater Seattle Area",
        "key": "Greater Seattle Area_350"
      },
      {
        "title": "Portland, Oregon Area",
        "key": "Portland, Oregon Area_351"
      },
      {
        "title": "Richland/Kennewick/Pasco, Washington Area",
        "key": "Richland/Kennewick/Pasco, Washington Area_352"
      },
      {
        "title": "Spokane, Washington Area",
        "key": "Spokane, Washington Area_353"
      },
      {
        "title": "Yakima, Washington Area",
        "key": "Yakima, Washington Area_354"
      }
    ],
    "title": "Washington ",
    "key": "Washington_348_state"
  },
  {
    "children": [
      {
        "title": "Charleston, West Virginia Area",
        "key": "Charleston, West Virginia Area_356"
      },
      {
        "title": "Cumberland, Maryland Area",
        "key": "Cumberland, Maryland Area_357"
      },
      {
        "title": "Huntington, West Virginia Area",
        "key": "Huntington, West Virginia Area_358"
      },
      {
        "title": "Parkersburg, West Virginia Area",
        "key": "Parkersburg, West Virginia Area_359"
      },
      {
        "title": "Steubenville, Ohio Area",
        "key": "Steubenville, Ohio Area_360"
      },
      {
        "title": "Washington D.C. Metro Area",
        "key": "Washington D.C. Metro Area_361"
      },
      {
        "title": "Wheeling, West Virginia Area",
        "key": "Wheeling, West Virginia Area_362"
      }
    ],
    "title": "West Virginia ",
    "key": "West Virginia_355_state"
  },
  {
    "children": [
      {
        "title": "Duluth, Minnesota Area",
        "key": "Duluth, Minnesota Area_364"
      },
      {
        "title": "Eau Claire, Wisconsin Area",
        "key": "Eau Claire, Wisconsin Area_365"
      },
      {
        "title": "Greater Milwaukee Area",
        "key": "Greater Milwaukee Area_366"
      },
      {
        "title": "Greater Minneapolis-St. Paul Area",
        "key": "Greater Minneapolis-St. Paul Area_367"
      },
      {
        "title": "Green Bay, Wisconsin Area",
        "key": "Green Bay, Wisconsin Area_368"
      },
      {
        "title": "Janesville/Beloit, Wisconsin Area",
        "key": "Janesville/Beloit, Wisconsin Area_369"
      },
      {
        "title": "La Crosse, Wisconsin Area",
        "key": "La Crosse, Wisconsin Area_370"
      },
      {
        "title": "Madison, Wisconsin Area",
        "key": "Madison, Wisconsin Area_371"
      },
      {
        "title": "Oshkosh, Wisconsin Area",
        "key": "Oshkosh, Wisconsin Area_372"
      },
      {
        "title": "Sheboygan, Wisconsin Area",
        "key": "Sheboygan, Wisconsin Area_373"
      },
      {
        "title": "Wausau, Wisconsin Area",
        "key": "Wausau, Wisconsin Area_374"
      }
    ],
    "title": "Wisconsin ",
    "key": "Wisconsin_363_state"
  },
  {
    "children": [
      {
        "title": "Casper, Wyoming Area",
        "key": "Casper, Wyoming Area_376"
      },
      {
        "title": "Cheyenne, Wyoming Area",
        "key": "Cheyenne, Wyoming Area_377"
      }
    ],
    "title": "Wyoming ",
    "key": "Wyoming_375_state"
  }
]
const locationHash = {}
locations.forEach(item=>{
    locationHash[item.key.trim()] = item.children.map(child=> child.key)
    
})

const regions = [
  {title:"NAM",key:"NAM"},
  {title:"APAC",key:"APAC"},
  {title:"EMEA",key:"EMEA"},
  {title:"LATAM",key:"LATAM"}
]
export { locations ,regions,locationHash};
