import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Form, Button, Tree, TreeSelect } from 'antd';
import { TOPICS } from "../../data/topics";
const ProfileFormInterests = (props) => {
  const [disabledBtn, setDisabledBtn] = useState(false)
  const { handleSubmit, setValues, selectedTopics = [], isEditMode = false, btnLoading } = props;
  const onTopicChange = (values) => { setValues({ topics: values }) }

  useEffect(() => {
    setDisabledBtn(selectedTopics.length ? 0 : 1)
  }, [selectedTopics])

  return (
    <Form name="dynamic_form_nest_item" onFinish={handleSubmit} autoComplete="off">

      <h6 className='custom-clr'>
        What are your buyers interested in?
           </h6>
      <p>
        <small>List items, topics, product categories, event names, and any other interesting keywords/ phrases your buyers might be interested in and/or "talking" about online.
          </small>
      </p>
      <p><br />
        <small>
          To use "Recommend New Interests" and "Grade my Buyer's Interests, you must enter atleast5 interests.
      </small>
      </p>


      <Tree
        style={{
          minHeight: "250px",
          maxHeight: "250px",
          overflow: "auto",
        }}
        checkable
        checkedKeys={selectedTopics}
        onCheck={onTopicChange}
        treeData={TOPICS}
      />
      <br />
      <TreeSelect
        {...{
          treeData: TOPICS,
          allowClear: true,
          value: selectedTopics,
          autoClearSearchValue: false,
          onChange: (checkedKeys) => {
            onTopicChange(checkedKeys)
          },

          treeCheckable: true,
          multiple: true,
          placeholder: "Please select",

          style: {
            width: "100%",
          },
        }}
      />
      {/* {
        selectedTopics.length > TOPIC_LIMIT ?
          <div class="bd-callout bd-callout-info">
            The max limit of topics is
          <code> {TOPIC_LIMIT}</code>.
          Please change it as per your requirment.Otherwise the first 30 will be used and rest  will be ignored.
        </div>
          : <></>
      } */}

      <br></br>
      <div className="float-right">

        <Button type="primary" htmlType="submit" style={{ marginTop: "10px" }}
          loading={btnLoading}
          disabled={disabledBtn}
        >

          {isEditMode ? 'Update Profile' : 'Complete Profile'}
        </Button>
      </div>
      <div className="float-left">
        <Link to={"/profiles"}>
          <Button type="dashed"
            style={{ marginTop: "10px" }}>
            Cancel
        </Button>
        </Link>
      </div>
    </Form>

  );
};

export default (ProfileFormInterests);


