import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  Table,
  ModalFooter,
  Spinner
} from "reactstrap";
import { Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import AudienceManagerService from '../../services/audience_manager';
const audienceManagerService = new AudienceManagerService();
const CsvImportModal = ({ csvModal, csvToggle, downloadCsv, onModelClose, ...rest }) => {
  const [state, setState] = useState({
    fileDetail: false,
    fileNameOrSuccess: "",
    selectedFile: null,
    loading: false,
    errorMsg: ""
  })
  const $uploadFile = async () => {
    try {
      setState({ loading: true, errorMsg: "" });
      const data = new FormData();
      data.append("file", state.selectedFile);
      let res = await audienceManagerService.uploadFile(data)
      setState({
        fileNameOrSuccess: res.msg,
        loading: false,
        selectedFile: null
      });
      setTimeout(() => {
        csvToggle();
        setState({
          fileNameOrSuccess: ''
        })
      }, 1000 * 3);

    } catch (error) {
      console.log(error)
      if (error.response && error.response.data.message) {
        setState({
          loading: false,
          errorMsg: error.response.data.message
        });
      } else {
        setState({
          loading: false,
          errorMsg: "Something went terribly wrong!"
        });
      }
    }
  };
  return (
    <div>
      {/* change permission modal */}
      <Modal
        isOpen={csvModal}
        toggle={csvToggle}
        className="modal-dialog-centered"
        backdrop={false}
        onClosed={onModelClose}
      >
        <ModalBody>
          <div className="pb-4">
            <span className="font-weight-bold custom-clr">
              Upload Target Account Csv File
              </span>
            <div
              className="float-right shadow-none mt-1 p-0 dropdown-toggle  custom-clr"
              size="sm"
              onClick={() => {
                setState({ fileDetail: !state.fileDetail });
              }}
            >
              Show file format
              </div>
          </div>
          <label className="btn btn-light w-100 d-flex">
            <span>
              <input
                type="file"
                className="filtTypeInput"
                accept=".csv, text/comma-separated-values,
                text/csv,application/csv,,application/vnd.ms-excel,application/vnd.msexcel,text/anytext"
                onChange={(event) => {

                  let types = [".csv", "text/comma-separated-values", "text/csv", "application/csv",
                    "application/excel", "application/vnd.ms-excel", "application/vnd.msexcel", 'text/anytext']
                  if (types.indexOf(event.target.files[0].type) === -1) {
                    setState({
                      errorMsg: 'Please upload only csv file.'

                    });
                  }
                  else {
                    setState({
                      fileNameOrSuccess: event.target.files[0].name,
                      selectedFile: event.target.files[0]
                    });
                  }

                }}
              />
            </span>


          </label>
          <div className={state.fileDetail ? "d-block" : "d-none"}>
            <ul className="mt-3">
              <li>The File must be CSV format, limit 2000/5 Million rows</li>
              <li>First row of the document must contain column header</li>
              <li>Column 1: Domain</li>
            </ul>
            <div className="text-danger">
              *Note : Any one of them should be provided.
              </div>
            <span>e.g</span>
            <Table striped={true} bordered={true}>
              <tbody>
                <tr>
                  <td>domain</td>

                </tr>
                <tr>
                  <td>google.com</td>

                </tr>
                <tr>
                  <td>google.com</td>

                </tr>
              </tbody>
            </Table>
          </div>
          {state.fileNameOrSuccess && (
            <div className="my-2 rounded p-2 alert-success">
              <span className="font-weight-bold">
                {state.fileNameOrSuccess}
              </span>
            </div>
          )}
          {state.errorMsg && (
            <div className="my-2 rounded p-2 alert-danger">
              <span className="">{state.errorMsg}</span>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="primary"
            size="sm"
            icon={<UploadOutlined />}
            className="float-right mt-3"
            disabled={!state.selectedFile ? true : false}
            onClick={$uploadFile}
          >
            {state.loading ? (
              <Spinner color="light" size="sm" />
            ) : (
                "Upload"
              )}
          </Button>
          <Button
            color="light"
            size="sm"
            className=" mt-3"
            onClick={() => {
              csvToggle();
              setState({
                selectedFile: null,
                fileNameOrSuccess: "",
                errorMsg: "",
                fileDetail: false
              });
            }}
          >
            Cancel
            </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default CsvImportModal