import React, { useState, useEffect } from 'react';
import {
  Button,
  Alert
} from 'antd';
import { TextField } from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import ProfileService from '../../services/profile'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
const profileService = new ProfileService();

const ProfileFormName = props => {
  const { id } = useParams();
  const { setValues, continueSubmit, name } = props;
  const [btnLoading, setBtnLoading] = useState(false)
  const [isNameExist, setIsNameExist] = useState(false)
  const {  control, handleSubmit, setValue } = useForm({ mode: "onChange" });


  const onFinish = async (value) => {
    setBtnLoading(true)
    try {
      const status = await profileService.checkName(value.name, id);
      if (status) {
        setIsNameExist(true)
      }
      else {
        setValues(value);
        continueSubmit()
      }
      setBtnLoading(false)
    } catch (error) {
      setBtnLoading(false)
    }



  }
  useEffect(() => {
    name && setValue('name', name);
  }, [name])

  return (
    <>
      <h6 className='custom-clr'>
        Name a Profile
           </h6>
      <p>
        <small>Enter a short label for your profile corresponding to your usecase or products category name that you are marketing.
          </small>
      </p>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onFinish)}>
        <Controller as={
          <TextField label="Profile Name"
            className='my-3' fullWidth variant="outlined" />
        }
          control={control}
          name='name'
          defaultValue=""
          rules={{
            required: true
          }}
        />

        {
          isNameExist ? <Alert message="Profile name alread exist.please try with different name" className='mb-2'

            type="error" showIcon /> : ''
        }


        <div class="float-right">
          <Button type="primary" htmlType="submit"
            loading={btnLoading}
          >
            Continue
           </Button>
        </div>
        <div className="float-left">
          <Link to={"/profiles"}>
            <Button type="dashed"
              style={{ marginTop: "10px" }}>
              Cancel
        </Button>
          </Link>
        </div>
      </form>
    </>

  );
};

export default (ProfileFormName);
