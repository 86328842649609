import React, { useState, useEffect } from "react";
import { Select, FormControl, InputLabel, MenuItem } from "@material-ui/core";

const CustomSelect = ({
	label,
	options,
	action,
	keyName,
	variant = "standard",
	className,
	value,
	labelWidth = label.length * 10,
	multiple = false,
}) => {
	const [values, setValues] = useState([]);
	useEffect(() => {
		if (multiple) {
			setValues(Array.isArray(value) ? value : value ? [value] : []);
		} else {
			setValues(value);
		}
	}, [value]);

	return (
		<FormControl
			fullWidth
			variant={variant}
			className={`customSelect ${className ? className : "mb-3"}`}
		>
			<InputLabel>{label}</InputLabel>
			<Select
				multiple={multiple}
				value={values}
				labelWidth={labelWidth}
				onChange={(event) => {
					const val = event.target.value;
					setValues(val);
					action(keyName, val);
				}}
			>
				{options.map((option) => {
					const value = option instanceof Object ? option.value : option;
					const name = option instanceof Object ? option.name : option;
					return (
						<MenuItem value={value} className="text-capitalize">
							{name}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};
export default CustomSelect;
