import React from 'react';


const data = [
  {
    title: 'Administrator',
    description: "Administrator can add, delete, edit 'Platform User' accounts (in addition to full access to all other system functionality"
  },
  {
    title: 'User',
    description: "A Platform User can add, delete, edit profiles and audiences and view audience results."
  },
];

const RoleList = () => {

  return (
    <div className="role-box">
      <div className="role-desc custom-clr">Role Description</div>
      {data.map(role => {
        return <small className="my-3 d-block">
          <b style={{ color: '#4e4e4e' }}>
            {role.title}
          </b>
          <div style={{ color: '#4e4e4e' }}>
            {role.description}
          </div>
        </small>
      })}
    </div>
  )

}
export default RoleList