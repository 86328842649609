
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { logout } from '../../actions'
import { Link } from 'react-router-dom'
import { Container } from "reactstrap";
import { Row, Col } from 'antd';
const tabs = [
  {
    href: "/users",
    title: `Users`,
    access: ['administrator']
  },
  {
    href: "/profiles",
    title: "Profiles",
    access: ['administrator', 'user']

  },
  {
    href: "/audiences",
    title: "Audiences",
    access: ['administrator', 'user']
  },
  {
    href: "/audience_managers",
    title: "Target Account List (TAL)",
    access: ['administrator', 'user']
  },

];
function Nav({ loading, token, logout, logInUser, ...props }) {

  const [activeTabs, setActiveTabs] = useState([]);
  useEffect(() => {
    const role = logInUser?.role?.name.toLowerCase();
    let allowTabs = []
    tabs.forEach(tab => {
      if (tab.access.indexOf(role) > -1) {
        allowTabs = [...allowTabs, tab]
      }
    })
    setActiveTabs(allowTabs)


  }, [logInUser])
  return (
    <>
      <Container fluid id='nav-bar' >
        <Row  >
          <Col className="d-flex px-0" id="tabBar-link">
            {activeTabs.map((tab) => {

              return <Link to={tab.href} key={tab.href} className={`btn py-2 text-primary decuration-none m-0
             bg-transparent rounded-0 shadow-none target ${props.match.path.search(tab.href) > -1 ? "active-nav" : ""
                }`}
              >
                {tab.title}
              </Link>

            })
            }

          </Col>
        </Row>
      </Container>
    </>
  )
}


const mapDispatchToProps = {
  logout: logout,
};

const mapStateToProps = (state) => (
  {
    loading: state.loading,
    token: state.token,
    logInUser: state.logInUser
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(Nav);