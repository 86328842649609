
import { logger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';
import reducer from '../reducers';
import { createStore, applyMiddleware,compose } from 'redux';
//enhancer for redux dev tools
const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer,composeEnhancers(applyMiddleware(sagaMiddleware, logger )));

sagaMiddleware.run(rootSaga);

export default store;
