import React, { useState } from "react";
import 'antd/dist/antd.css';
import './styles.css'
import { Button, Alert, Row, Col } from 'antd';

import { UploadOutlined } from '@ant-design/icons';
import SearchBar from "../components/commons/form/search";

import TargetAccountFiles from '../components/targetAccounts/targetAccountFiles'
import { useSelector } from "react-redux";
import CsvImportModal from '../components/targetAccounts/CsvImportModal'
const AudienceManagerPage = (props) => {

    const [searchKey, setSearchKey] = useState('');
    const state = useSelector((state) => state);
    const [pageRefresh, setPageRefresh] = useState(false)
    const [modalState, setModalState] = useState({
        csvModal: false,
        show: false,

    })
    const csvToggle = () => setModalState({ csvModal: !modalState.csvModal })
    const onModelClose = () => {
        setPageRefresh(!pageRefresh);
    }
    return (
        <div className="site-layout-content">

            {state.error &&
                (<Alert
                    message="There was an error while processing your request. Try again later!"
                    type="error"
                    closable
                />)}
            <Row  >
                <Col md={{ span: 20, offset: 2 }}>
                    <Row >
                        <Col md={{ span: 13 }}>
                            <div className='heading custom-clr' style={{ width: "100%" }}>
                                Target Account List (TAL)
                             </div>
                        </Col>
                        <Col md={{ span: 11 }}>
                            <div className="p-1 custom-clr" style={{ display: "flex", flexDirection: "column", marginLeft: "auto", border: "1px solid #001577", fontWeight: "400", fontSize: "smaller" }}>
                                <label className="m-0">Domains must be listed in column A – Single domain per cell</label>
                                <label className="m-0">No multiple tabs or additional formatting</label>
                                <label className="m-0">Must be saved as a .CSV</label>
                                <label className="m-0">Upload the TAL first. Then create the audience and select your uploaded TAL on step 4.</label>
                            </div>
                        </Col>
                    </Row>

                    <Row >
                        <Col md={{ span: 12 }}>
                            <SearchBar label="Search by Target Account List (TAL) Name"
                                className='my-3' name='name'
                                fullWidth
                                onChange={setSearchKey}
                                variant="outlined" />

                        </Col>
                        <Col md={{ span: 12 }}
                            style={{ display: "flex", justifyContent: 'flex-end', alignItems: "center" }}>

                            <Button type="primary"
                                shape="round"
                                size={'large'}
                                icon={<UploadOutlined />}
                                onClick={csvToggle}
                            >Import Csv </Button>

                        </Col>
                    </Row>
                    <Row className='my-3' >
                        <Col md={{ span: 24 }}>
                            <TargetAccountFiles searchKey={searchKey} pageRefresh={pageRefresh} />
                        </Col>
                    </Row>
                    <CsvImportModal
                        csvModal={modalState.csvModal}
                        csvToggle={csvToggle}
                        downloadCsv={() => { }}
                        onModelClose={onModelClose}

                    />
                </Col>
            </Row>

        </div>


    )
}

export default (AudienceManagerPage);

