const INDUSTRIES = [
  {
    "title": "Aerospace and Aviation",
    "key": "Aerospace and Aviation_1",
    "children": [
      {
        "title": "Aerospace & Defense",
        "key": "Aerospace & Defense_2"
      }
    ]
  },
  {
    "title": "Agriculture and Mining",
    "key": "Agriculture and Mining_3",
    "children": [
      {
        "title": "Agriculture",
        "key": "Agriculture_4"
      },
      {
        "title": "Metals & Mining",
        "key": "Metals & Mining_5"
      }
    ]
  },
  {
    "title": "Business Services",
    "key": "Business Services_6",
    "children": [
      {

        "title": "Business Services",
        "key": "Business Services_666",
      },
      {
        "title": "Accounting & Accounting Services",
        "key": "Accounting & Accounting Services_7"
      },
      {
        "title": "Auctions",
        "key": "Auctions_8"
      },
      {
        "title": "Call Centers & Business Centers",
        "key": "Call Centers & Business Centers_9"
      },
      {
        "title": "Commercial Printing",
        "key": "Commercial Printing_10"
      },
      {
        "title": "Debt Collection",
        "key": "Debt Collection_11"
      },
      {
        "title": "Facilities Management & Commercial Cleaning",
        "key": "Facilities Management & Commercial Cleaning_12"
      },
      {
        "title": "Human Resources & Staffing",
        "key": "Human Resources & Staffing_13"
      },
      {
        "title": "Information & Document Management",
        "key": "Information & Document Management_14"
      },
      {
        "title": "Management Consulting",
        "key": "Management Consulting_15"
      },
      {
        "title": "Multimedia & Graphic Design",
        "key": "Multimedia & Graphic Design_16"
      },
      {
        "title": "Security Products & Services",
        "key": "Security Products & Services_17"
      },
      {
        "title": "Translation & Linguistic Services",
        "key": "Translation & Linguistic Services_18"
      },
      {
        "title": "Food Service",
        "key": "Food Service_19"
      },
      {
        "title": "Business Services General",
        "key": "Business Services General_20"
      }
    ]
  },
  {
    "title": "Computers and Electronics",
    "key": "Computers and Electronics_21",
    "children": [
      {
        "title": "Computer Equipment & Peripherals",
        "key": "Computer Equipment & Peripherals_22"
      },
      {
        "title": "Computer Networking Equipment",
        "key": "Computer Networking Equipment_23"
      },
      {
        "title": "Computer Storage Equipment",
        "key": "Computer Storage Equipment_24"
      },
      {
        "title": "Consumer Electronics",
        "key": "Consumer Electronics_25"
      },
      {
        "title": "Consumer Electronics & Computers",
        "key": "Consumer Electronics & Computers_26"
      },
      {
        "title": "Personal Computers & Peripherals",
        "key": "Personal Computers & Peripherals_27"
      },
      {
        "title": "Network Security Hardware & Software",
        "key": "Network Security Hardware & Software_28"
      },
      {
        "title": "Electronics",
        "key": "Electronics_29"
      },
      {
        "title": "Electronic Components",
        "key": "Electronic Components_30"
      },
      {
        "title": "Batteries, Power Storage Equipment & Generators",
        "key": "Batteries, Power Storage Equipment & Generators_31"
      },
      {
        "title": "Power Conversion & Protection Equipment",
        "key": "Power Conversion & Protection Equipment_32"
      },
      {
        "title": "Semiconductor & Semiconductor Equipment",
        "key": "Semiconductor & Semiconductor Equipment_33"
      }
    ]
  },
  {
    "title": "Construction and Real Estate",
    "key": "Construction and Real Estate_34",
    "children": [
      {
        "title": "Construction",
        "key": "Construction_35"
      },
      {
        "title": "Real Estate",
        "key": "Real Estate_36"
      }
    ]
  },
  {
    "title": "Education",
    "key": "Education_37",
    "children": [
      {
        "title": "Education",
        "key": "Education_38"
      }
    ]
  },
  {
    "title": "Energy, Raw Materials and Utilities",
    "key": "Energy, Raw Materials and Utilities_39",
    "children": [
      {
        "title": "Energy, Utilities & Waste Treatment",
        "key": "Energy, Utilities & Waste Treatment_40"
      }
    ]
  },
  {
    "title": "Finance",
    "key": "Finance_41",
    "children": [
      {
        "title": "Finance",
        "key": "Finance_42"
      }
    ]
  },
  {
    "title": "Food and Beverage",
    "key": "Food and Beverage_43",
    "children": [
      {
        "title": "Food & Beverages",
        "key": "Food & Beverages_44"
      },
      {
        "title": "Wineries & Breweries",
        "key": "Wineries & Breweries_45"
      }
    ]
  },
  {
    "title": "Government",
    "key": "Government_46",
    "children": [
      {
        "title": "Government",
        "key": "Government_47"
      },
      {
        "title": "Chambers of Commerce",
        "key": "Chambers of Commerce_48"
      },
      {
        "title": "Cities, Towns & Municipalities",
        "key": "Cities, Towns & Municipalities_49"
      }
    ]
  },
  {
    "title": "Healthcare, Pharmaceuticals and Biotech",
    "key": "Healthcare, Pharmaceuticals and Biotech_50",
    "children": [
      {
        "title": "Healthcare",
        "key": "Healthcare_51"
      }
    ]
  },
  {
    "title": "Insurance",
    "key": "Insurance_52",
    "children": [
      {
        "title": "Insurance",
        "key": "Insurance_53"
      }
    ]
  },
  {
    "title": "Legal",
    "key": "Legal_54",
    "children": [
      {
        "title": "Law Firms & Legal Services",
        "key": "Law Firms & Legal Services_55"
      }
    ]
  },
  {
    "title": "Manufacturing",
    "key": "Manufacturing_56",
    "children": [
      {
        "title": "Manufacturing",
        "key": "Manufacturing_566",
      },
      {
        "title": "Motor Vehicles",
        "key": "Motor Vehicles_57"
      },
      {
        "title": "Boats & Submarines",
        "key": "Boats & Submarines_58"
      },
      {
        "title": "Aggregates, Concrete & Cement",
        "key": "Aggregates, Concrete & Cement_59"
      },
      {
        "title": "Lumber, Wood Production & Timber Operations",
        "key": "Lumber, Wood Production & Timber Operations_60"
      },
      {
        "title": "Miscellaneous Building Materials (Flooring, Cabinets, etc.)",
        "key": "Miscellaneous Building Materials (Flooring, Cabinets, etc.)_61"
      },
      {
        "title": "Plumbing & HVAC Equipment",
        "key": "Plumbing & HVAC Equipment_62"
      },
      {
        "title": "Chemicals",
        "key": "Chemicals_63"
      },
      {
        "title": "Gases",
        "key": "Gases_64"
      },
      {
        "title": "Glass & Clay",
        "key": "Glass & Clay_65"
      },
      {
        "title": "Petrochemicals",
        "key": "Petrochemicals_66"
      },
      {
        "title": "Appliances",
        "key": "Appliances_67"
      },
      {
        "title": "Cleaning Products",
        "key": "Cleaning Products_68"
      },
      {
        "title": "Cosmetics, Beauty Supply & Personal Care Products",
        "key": "Cosmetics, Beauty Supply & Personal Care Products_69"
      },
      {
        "title": "Health & Nutrition Products",
        "key": "Health & Nutrition Products_70"
      },
      {
        "title": "Household Goods",
        "key": "Household Goods_71"
      },
      {
        "title": "Jewelry & Watches",
        "key": "Jewelry & Watches_72"
      },
      {
        "title": "Pet Products",
        "key": "Pet Products_73"
      },
      {
        "title": "Photographic & Optical Equipment",
        "key": "Photographic & Optical Equipment_74"
      },
      {
        "title": "Sporting Goods",
        "key": "Sporting Goods_75"
      },
      {
        "title": "Textiles & Apparel",
        "key": "Textiles & Apparel_76"
      },
      {
        "title": "Hand, Power and Lawn-care Tools",
        "key": "Hand, Power and Lawn-care Tools_77"
      },
      {
        "title": "Tobacco",
        "key": "Tobacco_78"
      },
      {
        "title": "Furniture",
        "key": "Furniture_79"
      },
      {
        "title": "Industrial Machinery & Equipment",
        "key": "Industrial Machinery & Equipment_80"
      },
      {
        "title": "Medical Devices & Equipment",
        "key": "Medical Devices & Equipment_81"
      },
      {
        "title": "Plastic, Packaging & Containers",
        "key": "Plastic, Packaging & Containers_82"
      },
      {
        "title": "Pulp & Paper",
        "key": "Pulp & Paper_83"
      },
      {
        "title": "Telecommunication Equipment",
        "key": "Telecommunication Equipment_84"
      },
      {
        "title": "Test & Measurement Equipment",
        "key": "Test & Measurement Equipment_85"
      },
      {
        "title": "Tires & Rubber",
        "key": "Tires & Rubber_86"
      },
      {
        "title": "Toys & Games",
        "key": "Toys & Games_87"
      },
      {
        "title": "Wire & Cable",
        "key": "Wire & Cable_88"
      },
      {
        "title": "Motor Vehicle Parts",
        "key": "Motor Vehicle Parts_89"
      },
      {
        "title": "Manufacturing General",
        "key": "Manufacturing General_90"
      }
    ]
  },
  {
    "title": "Marketing, Advertising and Public Relations",
    "key": "Marketing, Advertising and Public Relations_91",
    "children": [
      {
        "title": "Advertising & Marketing",
        "key": "Advertising & Marketing_92"
      }
    ]
  },
  {
    "title": "Media, Entertainment and Publishing",
    "key": "Media, Entertainment and Publishing_93",
    "children": [
      {
        "title": "Media & Internet",
        "key": "Media & Internet_94"
      }
    ]
  },
  {
    "title": "Non-Profit",
    "key": "Non-Profit_95",
    "children": [
      {
        "title": "Organizations",
        "key": "Organizations_96"
      },
      {
        "title": "Cultural",
        "key": "Cultural_97"
      }
    ]
  },
  {
    "title": "Retail",
    "key": "Retail_98",
    "children": [
      {
        "title": "Retail",
        "key": "Retail_99"
      },
      {
        "title": "Consumer Services",
        "key": "Consumer Services_100"
      }
    ]
  },
  {
    "title": "Software, Internet and Technology",
    "key": "Software, Internet and Technology_101",
    "children": [
      {
        "title": "Software",
        "key": "Software_102"
      }
    ]
  },
  {
    "title": "Telecommunications",
    "key": "Telecommunications_103",
    "children": [
      {
        "title": "Telecommunications",
        "key": "Telecommunications_104"
      }
    ]
  },
  {
    "title": "Transportation",
    "key": "Transportation_105",
    "children": [
      {
        "title": "Transportation",
        "key": "Transportation_1056",
      },
      {
        "title": "Airlines, Airports & Air Services",
        "key": "Airlines, Airports & Air Services_106"
      },
      {
        "title": "Marine Shipping & Transportation",
        "key": "Marine Shipping & Transportation_107"
      },
      {
        "title": "Rail, Bus & Taxi",
        "key": "Rail, Bus & Taxi_108"
      },
      {
        "title": "Trucking, Moving & Storage",
        "key": "Trucking, Moving & Storage_109"
      },
      {
        "title": "Transportation General",
        "key": "Transportation General_110"
      }
    ]
  },
  {
    "title": "Travel, Hotel, Restaurant and Recreation",
    "key": "Travel, Hotel, Restaurant and Recreation_111",
    "children": [
      {
        "title": "Hospitality",
        "key": "Hospitality_112"
      }
    ]
  },
  {
    "title": "Wholesale and Distribution",
    "key": "Wholesale and Distribution_113",
    "children": [
      {
        "title": "Freight & Logistics Services",
        "key": "Freight & Logistics Services_114"
      }
    ]
  }
]
const IndustriesTopLevel = INDUSTRIES.map(x => {
  return {
    title: x.title,
    key: x.key
  }
})

export { INDUSTRIES, IndustriesTopLevel };