import React from "react";
import { Row, Col } from "antd";
import "antd/dist/antd.css";
import "./styles.css";

import UserForm from "../components/users/UserForm";
import RoleList from "../components/users/RoleList";
import { connect } from "react-redux";
const ViewUser = ({ token }) => {
	return (
		<div>
			<div className="site-layout-content">
				<Row>
					<Col md={{ span: 9, offset: 3 }}>
						<div className="heading custom-clr">View user for PureB2B</div>
						<UserForm isViewMode={true} isAddMode={false} />
					</Col>
					<Col md={12} style={{ justifyContent: "center", display: "flex" }}>
						<RoleList />
					</Col>
				</Row>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	token: state.token,
});

export default connect(mapStateToProps)(ViewUser);
