import { cloneDeep } from "lodash";

export default function useMultiSelectCheckbox(list, setList, initialList) {
  return (val, isChecked, valIndex) => {
    if (list.filter((item) => item.isChecked === true).length >= 1) {
      let firstCheckedItemIndex = list.findIndex(
        (item) => item.isChecked === true
      );
      let lastCheckedItemIndex;

      if (firstCheckedItemIndex > valIndex) {
        lastCheckedItemIndex = firstCheckedItemIndex;
        firstCheckedItemIndex = valIndex;
      } else {
        lastCheckedItemIndex = valIndex;
      }

      setList((prev) =>
        prev.map((item, itemIndex) => {
          if (
            itemIndex >= firstCheckedItemIndex &&
            (isChecked
              ? itemIndex <= lastCheckedItemIndex
              : itemIndex < lastCheckedItemIndex)
          ) {
            return { ...item, isChecked: true };
          } else {
            return { ...item, isChecked: false };
          }
        })
      );
    } else {
      if (isChecked === false) {
        setList(initialList);
      } else {
        const revenueToUpdate = list.findIndex((item) => item.value === val);
        const listClone = cloneDeep(list);
        listClone[revenueToUpdate] = {
          ...listClone[revenueToUpdate],
          isChecked,
        };
        setList(listClone);
      }
    }
  };
}
