import {
  userColumns, profileColumns, audienceColumns, processUsers,
  processAudiences, processProfiles, audienceDetailsColumns,
  AudienceManagerColumns
} from '../utils'

const initialState = {
  requesting: false,
  loading: false,
  users: [],
  profiles: [],
  audiences: [],
  profileColumns: profileColumns(),
  audienceColumns: audienceColumns(),
  audienceManagerColumns: AudienceManagerColumns(),
  error: '',
  userColumns: userColumns(),
  token: window.localStorage.token,
  success: false,
  audienceDetails: [],
  audienceDetailsColumns: audienceDetailsColumns(),
  audienceFilters: {},
  refresh: false,
  refreshOnDelete: false,
  currentUser: {},
  logInUser: JSON.parse(window.localStorage.getItem('pureb2b_user') || "{}"),
  showCampaignModal:false,
  selectedAudienceId:null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CURRENTUSER':
      return { ...state }
    case 'GET_CURRENTUSER_RECIEVED':
      localStorage.setItem('pureb2b_user', JSON.stringify(action.user))
      return { ...state, logInUser: action.user, token: Object.keys(action.user).length ? state.token : undefined }
    case 'GET_USERS':
      return { ...state, loading: true }
    case 'GET_USERS':
      return { ...state, loading: true }
    case 'LOGOUT':
      localStorage.setItem("token", undefined);
      localStorage.setItem("token", undefined);
      return { ...initialState, token: undefined }
    case 'LOGIN_SUCCESS':
      localStorage.setItem("token", action.token);
      localStorage.setItem('pureb2b_user', JSON.stringify(action.user))
      return { ...state, error: '', loading: false, token: action.token, currentUser: action.user }
    case 'LOGIN_ERROR':
      return { ...state, error: action.error }

    case 'GET_PROFILES':
      return { ...state, loading: true, refresh: false }
    case 'GET_PROFILE':
      return { ...state, loading: true }
    case 'AUDIENCE_DETAILS_RECIEVED':
      return { ...state, loading: false, audienceDetails: action.data }
    case 'AUDIENCE_FILTERS_RECIEVED':
      return { ...state, audienceFilters: action.data }
    case 'GET_AUDIENCE_FILTER_VALUES':
      return { ...state, loading: true }
    case 'GET_AUDIENCE_DETAILS':
      return { ...state, loading: true }
    case 'GET_AUDIENCES':
      return { ...state, loading: true }
    case 'CREATE_PROFILE':
      return { ...state, loading: true }
    case 'UPDATE_PROFILE':
      return { ...state, loading: true }
    case 'CREATE_AUDIENCE':
      return { ...state, loading: true }
    case 'DELETE_PROFILE':
      return { ...state, loading: true }
    case 'DELETE_AUDIENCE':
      return { ...state, loading: true }
    case 'REFRESH_AUDIENCE':
      return { ...state, loading: true }
    case 'SHOW_CAMPAIGN_MODAL':
      return { ...state, showCampaignModal:true , selectedAudienceId:action.id}
      case 'HIDE_CAMPAIGN_MODAL':
        return { ...state, showCampaignModal:false}
    case 'DELETE_AUDIENCE_MANAGER':
      return { ...state, loading: true }
    case 'PROFILE_DELETED':
      return { ...state, loading: false, refresh: true }
    case 'AUDIENCE_DELETED':
      return { ...state, loading: false, refresh: true, refreshOnDelete: !state.refreshOnDelete }
    case 'AUDIENCE_REFRESHED':
      return { ...state, loading: false, refresh: true, refreshOnDelete: !state.refreshOnDelete }
    case 'AUDIENCE_MANAGER_DELETED':
      return { ...state, loading: false, refresh: !state.refresh }
    case 'CAMPAIGN_ADDED':
    return {
      ...state,loading:false,refreshOnDelete: !state.refreshOnDelete,showCampaignModal:false
    }
    case 'USER_DELETED':
      return { ...state, loading: false, refresh: true }
    case 'PROFILE_CREATED':
      return { ...state, loading: false, success: true }
    case 'AUDIENCE_CREATED':
      return { ...state, loading: false, success: true }
    case 'CREATE_USER':
      return { ...state, loading: true }
    case 'PROFILES_RECIEVED':
      return { ...state, loading: false, profiles: processProfiles(action.data), success: false }
    case 'PROFILE_RECIEVED':
      return { ...state, loading: false, profile: action.data, success: false }
    case 'UPDATE_PROFILE_RECIEVED':
      return { ...state, loading: false, profile: action.data, success: false }

    case 'AUDIENCES_RECIEVED':
      return { ...state, loading: false, audiences: processAudiences(action.data), success: false }
    case 'USERS_RECIEVED':
      return { ...state, loading: false, users: processUsers(action.data), success: false }
    case 'USER_CREATED':
      return { ...state, loading: false, success: true }
    default:
      return { ...state, loading: false }
  }
};

export default reducer;