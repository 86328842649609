import React, { useEffect, useState } from "react";
import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import { Button, Alert, Row, Col, Divider } from 'antd';
import { getAudiences } from '../actions';
import AudiencesTable from '../components/audiences/AudiencesTable'
import { Link } from 'react-router-dom'
import './styles.css'
import { PlusOutlined } from '@ant-design/icons';
import CustomSelect from '../components/commons/form/select'
import MultiSelect from '../components/commons/form/multiSelect'
import SearchBar from "../components/commons/form/search";
import ProfileService from '../services/profile'
import UserService from '../services/user'
import { DefaultAudeinceFilter } from '../utils/audiencefilter'
import moment from 'moment'
const userService = new UserService();
const profileService = new ProfileService();

const AudiencePage = ({ getAudiences, error, token, audiences, refreshOnDelete }) => {
  const applyBtn = React.createRef();
  const [searchKey, setSearchKey] = useState({ name: '' });
  const [searchVal, setSearchVal] = useState();
  const [refresh, setReresh] = useState(1);
  const [filterClause] = useState(DefaultAudeinceFilter)
  const [filters, setFilters] = useState(DefaultAudeinceFilter)
  const [filterData, setFilterData] = useState({
    users: {},
    profiles: {},
    createdDate: { 'Yesterday': 1, 'Last Week': 7, 'Last 30 days': 30, 'Last 60 days': 60 }
  })

  const saveValue = (key, value) => {
    setFilters({
      ...filters, [key]: value
    });
  }
  const getFilters = () => {
    const profiles = filters.profiles.map(profile => filterData.profiles[profile]);
    let createdAt = filters.createdAt ? moment().subtract(filterData.createdDate[filters.createdAt], 'days').utc() : null;
    return { ...filterClause, ...filters, profiles, createdAt, ...searchKey }
  }
  const applyFilter = () => {
    setReresh(refresh + 1)
  }
  useEffect(() => {
    getAudiences(token, getFilters())
  }, [searchKey, refresh, refreshOnDelete]);

  useEffect(() => {
    async function getFilterData() {
      const users = await userService.getAll();
      const profiles = await profileService.getAll();
      const userObj = {};
      users.forEach(user => {
        userObj[user.email] = user._id
      })
      const profileObj = {};
      profiles.forEach(profile => {
        profileObj[profile.name] = profile._id
      })
      setFilterData({
        ...filterData,
        users: { ...filterData.users, ...userObj },
        profiles: { ...filterData.profiles, ...profileObj }
      })

    }
    getFilterData()
  },[])

  return (
    <div className="site-layout-content audience">
      {error &&
        (<Alert
          message="There was an error while processing your request. Try again later!"
          type="error"
          closable
        />)}
      <Row  >
        <Col md={{ span: 22, offset: 1 }}>
          <Row>
            <Col md={{ span: 5 }}>
              <SearchBar label="Search Audiences"
                value={searchVal}
                setValue={setSearchVal}
                onChange={setSearchKey}
                style={{ marginTop: '11px' }}
                name='name'
                fullWidth
                variant="outlined" />
              <Divider />

              <MultiSelect
                label='PureB2B PROFILES'
                variant='outlined'
                keyName='profiles'
                action={saveValue}
                multiple={true}
                labelWidth={145}
                className='mb-4'
                value={filters.profiles || []}
                options={Object.keys(filterData.profiles) || []}
              />


              <CustomSelect label='CREATED DATE'
                variant='outlined'
                keyName='createdAt'
                className='mb-4'
                labelWidth={120}
                action={saveValue}
                value={filters.createdAt}
                options={Object.keys(filterData.createdDate)} />

              <MultiSelect
                label='CREATED BY'
                variant='outlined'
                keyName='users'
                action={saveValue}
                multiple={true}
                labelWidth={105}
                className='mb-4'
                value={filters.users || []}
                options={Object.keys(filterData.users) || []}
              />
              <Row gutter={16} className="mt-4">
                <Col span={12}>

                  <Button className='w-100'
                    onClick={() => {
                      setFilters(DefaultAudeinceFilter)
                      setSearchKey({})
                      setSearchVal('')
                      setReresh(refresh + 1);


                    }}
                  >Clear</Button>
                </Col>
                <Col span={12}>
                  <Button type="primary" className="w-100"
                    ref={applyBtn}
                    onClick={applyFilter}
                  >Apply</Button>
                </Col>
              </Row>

            </Col>
            <Col md={{ span: 19 }} className='px-3'>


              <Row >
                <Col md={{ span: 12 }}>
                  <div className='heading custom-clr'>
                    Audiences ({audiences.length})
                        </div>
                </Col>
                <Col md={{ span: 12 }} style={{ display: "flex", justifyContent: 'flex-end', alignItems: "center" }}>
                  <Link to={"/audiences/new"}>
                    <Button type="primary"
                      shape="round"
                      size={'large'}
                      icon={<PlusOutlined />}
                    >Create New Audience</Button>
                  </Link>
                </Col>
              </Row>
              <Row className='my-3' >
                <Col md={{ span: 24 }}>

                  <AudiencesTable />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>

  )
}
const mapDispatchToProps = {
  getAudiences: getAudiences,
};

const mapStateToProps = (state) => (
  {
    error: state.error,
    token: state.token,
    audiences: state.audiences,
    refreshOnDelete: state.refreshOnDelete
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(AudiencePage);

