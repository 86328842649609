import React, { useEffect, useState } from "react";
import { Spin, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getProfiles } from "../../actions";
function ProfileList({
	searchKey,
	rowSelection,
	boxShadow = true,
	removeAction = false,
	...rest
}) {
	let { token, profiles, profileColumns, loading, refresh } = useSelector(
		(state) => state
	);
	const [cols, setCols] = useState(profileColumns);
	const dispatch = useDispatch();

	useEffect(() => {
		if (removeAction) {
			const cols = JSON.parse(JSON.stringify(profileColumns));
			cols.splice(cols.length - 1, 1);
			setCols(cols);
		}
		dispatch(getProfiles(token, searchKey));
	}, [token, searchKey, refresh]);

	return (
		<>
			<Spin spinning={loading}>
				<Table
					className={"profileTable"}
					rowSelection={
						rowSelection
							? {
									type: "checkbox",
									...rowSelection,
							  }
							: ""
					}
					bordered={true}
					style={
						boxShadow ? { boxShadow: "0 7px 13px 0 rgba(0, 0, 0, 0.06)" } : {}
					}
					columns={cols}
					dataSource={profiles || null}
					pagination={{ size: "small" }}
				/>
			</Spin>
		</>
	);
}

export default ProfileList;
