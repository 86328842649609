import axios from "../axios-configured";
import CrudService from './crud'
class ProfileService extends CrudService {
    constructor() {
        const subUrl = '/profiles'
        super(subUrl)
        this.subUrl = subUrl
    }
    async checkName(name, id = null) {
        let url = `${this.subUrl}/checkName/${name}`
        if (id) {
            url += `?id=${id}`
        }
        return axios.get(url)
    }

}
export default ProfileService
