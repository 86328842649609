import React from "react";
import { Row, Col } from 'antd';
import 'antd/dist/antd.css';
import './styles.css'
import ProfileForm from '../components/profiles/ProfileForm'
const EditProfile = ({ props }) => {
  return (
    <div className="site-layout-content">

      <Row >
        <Col md={{ span: 20, offset: 2 }}>
          <div className='heading custom-clr'>
            PureB2B Profile
          </div>
          <Row >
            <Col span={24}>
              <ProfileForm isEditMode={true} />
            </Col>
          </Row>
        </Col>
      </Row>


    </div>



  )
}
export default (EditProfile);