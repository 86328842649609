import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

const PublicRoute = ({
  component: Component,
  token,
  ...rest
}) => (
    <Route
      {...rest}
      render={(props) =>
        <Component {...props} />
      }
    />
  );

const mapStateToProps = (state) => ({
  auth: state.auth,
  token: state.token
});

export default connect(mapStateToProps)(PublicRoute);
