import axios from "../axios-configured";
import CrudService from './crud'
class AudienceService extends CrudService {
    constructor() {
        const subUrl = '/audiences'
        super(subUrl)
        this.subUrl = subUrl
    }
    async getSideBarFilter(id) {
        let url = `${this.subUrl}/${id}/filterValues`
        return axios.get(url)
    }
    async getDomains(id, text) {
        let url = `${this.subUrl}/${id}/filterValues/search?type=domain&text=${text}`
        return axios.get(url)
    }

}
export default AudienceService
