
import moment from 'moment'
export const DefaultAudeinceFilter = {
    profiles: [],
    users: [],
    createdAt: '',
    name: ''

}


export const convertAudienceFilterIntoQuery = (filter) => {
    let {
        profiles, users, createdAt, name
    } = filter

    let queryObj = {
        profiles: profiles && profiles.length ? { $in: profiles } : undefined || undefined,
        'createdBy.email': users && users.length ? { $in: users } : undefined || undefined,
        'createdAt': createdAt ? {
            $gte: createdAt.startOf('day').toISOString(),
            $lte: moment().utc().toISOString()
        } : undefined || undefined,
        'name': name || undefined

    }
    console.log('queryObj', queryObj)
    return JSON.stringify(queryObj);



}
