import React, { useEffect,useState } from 'react'
import { Spin, Table ,Modal, Input} from 'antd';
import { connect } from 'react-redux';
import { useDispatch } from "react-redux";
import {  hideCampaignModal,CampaignAdded } from '../../actions';
import AudienceService from "../../services/audience";
const audienceService = new AudienceService();
function AudiencesTable({ loading, audienceColumns, audiences,showCampaignModal,selectedAudienceId }) {
  const dispatch = useDispatch();
  useEffect(() => {
  }, [audiences, audienceColumns]);
  const [campaignId,setCampaignId]  = useState(null);
  const [updatingCampaign,setUpdatingCampaign] = useState(false)
  return (
    <>
     <Modal title="Campaign" visible={showCampaignModal} 
     confirmLoading={updatingCampaign}
     onOk={async()=>{
        if(selectedAudienceId){
          setUpdatingCampaign(true)
          await audienceService.update(selectedAudienceId,{
            campaignId
          })
          setUpdatingCampaign(false);
          dispatch(CampaignAdded())
          
        }
     }} 
     onCancel={()=>{
      dispatch(hideCampaignModal())
     }}>
          <Input onChange={(val)=>{
            setCampaignId(val.target.value)}}
            placeholder="Enter Campaign Id"
            />
        </Modal>
      <Spin spinning={loading}>
        <div className='row-spacer audiencetable'>
          <Table
            bordered={true}

            columns={audienceColumns}
            dataSource={audiences || null}
            pagination={{ size: "small" }}
          />
        </div>
      </Spin>

    </>
  )
}

const mapStateToProps = (state) => (
  {
    loading: state.loading,
    audiences: state.audiences,
    audienceColumns: state.audienceColumns,
    showCampaignModal:state.showCampaignModal,
    selectedAudienceId:state.selectedAudienceId
  }
);

export default connect(mapStateToProps)(AudiencesTable);