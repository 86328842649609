/* eslint-disable no-use-before-define */
import React, { useCallback } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
	root: {
		"& > * + *": {
			marginTop: theme.spacing(3),
		},
	},
}));

const MultiSelect = ({
	label,
	options,
	loading = false,
	action,
	selectedText,
	keyName,
	className,
	value,
	isAsync = false,
	labelWidth = label.length * 10,
}) => {
	const classes = useStyles();
	const debounce = useCallback(
		_.debounce((_searchVal) => {
			selectedText(_searchVal);
		}, 500),
		[]
	);
	return (
		<div className={classes.root}>
			<Autocomplete
				className={className}
				multiple
				options={options}
				getOptionLabel={(option) => option}
				filterSelectedOptions
				loading={loading}
				onChange={(event, newValue) => {
					action(keyName, newValue);
				}}
				value={value}
				renderInput={(params) => (
					<TextField
						className="text-capitalize"
						{...params}
						variant="outlined"
						label={label}
						placeholder={"Search"}
						onChange={(e) => {
							isAsync && debounce(e.target.value);
						}}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{loading ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</>
							),
						}}
					/>
				)}
			/>
		</div>
	);
};
export default MultiSelect;
