import React, { useState } from "react";
import { Modal, Button, Alert, message } from "antd";
import { TextField } from "@material-ui/core";
import UserService from "../../services/user";
const userService = new UserService();

const ResetPassword = ({ id, ...rest }) => {
	const [visible, setVisible] = useState(false);
	const [state, setState] = useState({});
	const [errMsg, setErrMsg] = useState(null);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const showModal = () => {
		setVisible(true);
	};
	const handleCancel = () => {
		setVisible(false);
	};

	const handleOk = async () => {
		if (!state.oldPwd) {
			setErrMsg("Old password is required!");
			return;
		} else if (!state.newPwd) {
			setErrMsg("New password is required!");
			return;
		}
		setConfirmLoading(true);
		try {
			await userService.resetPwd(id, state);
			setVisible(false);
			message.success({
				content: "Your password has been reset!",
			});
		} catch (error) {
			setErrMsg(error.errMsg);
		}
		setConfirmLoading(false);
	};

	return (
		<>
			<a onClick={showModal}>Reset Password</a>
			<Modal
				maskClosable={false}
				title="Reset Password"
				visible={visible}
				onCancel={handleCancel}
				confirmLoading={confirmLoading}
				footer={[
					<Button key="back" onClick={handleCancel}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={confirmLoading}
						onClick={handleOk}
					>
						Update Password
					</Button>,
				]}
			>
				<TextField
					label="Old Password"
					fullWidth
					type="password"
					className="mt-1 mb-2"
					variant="outlined"
					onChange={(event) =>
						setState({ ...state, oldPwd: event.target.value })
					}
				/>
				<TextField
					label="New Password"
					fullWidth
					type="password"
					className="mt-1 mb-2"
					variant="outlined"
					onChange={(event) =>
						setState({ ...state, newPwd: event.target.value })
					}
				/>
				{errMsg ? (
					<Alert message={errMsg} className="mb-2" type="error" showIcon />
				) : (
					""
				)}
			</Modal>
		</>
	);
};

export default ResetPassword;
