import React from "react";
import { Tooltip, Popover } from "antd";
const TAConstants = {
    MAX_TD_ROWS: 3
};

const remainingItmes = (data) => (
  <ul
    style={{
      overflowY: "auto",
      maxHeight: "150px",
      listStyleType: "none",
      padding: 0,
    }}
  >
    {data &&
      data.map((item, index) => {
        return (
          <li className="text-capitalize" key={`remaining_${index}`}>
            {item}
          </li>
        );
      })}
  </ul>
);

const DataList = ({data}) => {
  data && data.sort((a, b) => {
    return b.score - a.score
  })
  return (
    <td>
      {data &&
        data.slice(0, TAConstants.MAX_TD_ROWS).map((topic, index) => {
          return (
            <Tooltip
              key={`topic_${index}`}
              title={<span className="text-capitalize">{topic}</span>}
            >
              <div className="d-flex font-size-12">
                <small
                  className="d-inline-block text-capitalize text-truncate "
                  style={{ maxWidth: "120px" }}
                >
                  {topic}
                </small>
              </div>
            </Tooltip>
          );
        })}
      {data && data.length > TAConstants.MAX_TD_ROWS && (
        <Popover content={remainingItmes(data)}>
          <small
            className="d-inline-block text-truncate"
            style={{ maxWidth: "120px", color: "#007bff", cursor: "cursor" }}
          >
            View All {data.length} 
          </small>
        </Popover>
      )}
    </td>
  );
};

export default DataList;
