import React, { useState } from "react";
import { reduxForm } from "redux-form";
import { Form, Button, Row, Col } from "antd";
import { Link } from "react-router-dom";
import ProfileList from "../commons/ProfileList";
import SearchBar from "../commons/form/search";
const AudienceFormProfiles = (props) => {
  const { handleSubmit, setValues } = props;
  const [selected, setSelected] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected([...selected, selectedRowKeys]);
      setDisabledBtn(selectedRowKeys.length ? 0 : 1);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const onFinish = () => {
    setValues({ profiles: selected });
  };

  return (
    <>
    <Row>
        <Col md={{ span: 15 }}>
          <h6 className="custom-clr">Step 2: Choose Profile</h6>
          <small className="d-block">
            Enter a short label for your profile corresponding to your usecase
            or product category name that you are marketing
          </small>
        </Col>
        <Col md={{ span: 9 }}>
          <SearchBar
            label="Search by Profile Name"
            name="name"
            fullWidth
            onChange={setSearchKey}
            variant="outlined"
            
          />
        </Col>
      </Row>

      <br></br>
      <Form
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      onSubmitCapture={handleSubmit}
      autoComplete="off"
    >
      <ProfileList
        searchKey={searchKey}
        boxShadow={false}
        removeAction={true}
        rowSelection={rowSelection}
      ></ProfileList>
      <Form.Item>
        <div className="float-right">
          <Button
            type="primary"
            htmlType="submit"
            disabled={disabledBtn}
            style={{ marginTop: "10px" }}
          >
            Continue
          </Button>
        </div>
        <div className="float-left">
          <Link to={"/audiences"}>
            <Button type="dashed" style={{ marginTop: "10px" }}>
              Cancel
            </Button>
          </Link>
        </div>
      </Form.Item>
    </Form>
      
    </>
  );
};

export default reduxForm({
  form: "wizard", //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
})(AudienceFormProfiles);
