import React from "react";
import 'antd/dist/antd.css';
import { Form, Input, Button, Card, Alert } from 'antd';
import { loginUser } from '../actions'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { MailOutlined, LockOutlined } from '@ant-design/icons';

const LoginForm = ({ loginUser, token, error, loading, logInUser }) => {

  const onFinish = (values) => {
    loginUser(values)
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Card className='shadow-sm'>
      {
        error ? <Alert className='my-3'
          message={error}
          type="error"
          showIcon
        /> : ''
      }

      <Form
        layout={'vertical'}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {token && token !== "undefined" && <Redirect to={logInUser?.role?.name.toLowerCase() === 'administrator' ? "/users" : '/profiles'} />}
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid Email!',
            },
            {
              required: true,
              message: 'Please input your Email!',
            },
          ]}
        >
          <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />

        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
          hasFeedback
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item style={{ textAlign: 'end' }}>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Submit
        </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}


const mapDispatchToProps = {
  loginUser: loginUser,
};

const mapStateToProps = (state) => (
  {
    loading: state.loading,
    token: state.token,
    error: state.error,
    logInUser: state.logInUser

  }
);

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);