import React from "react";
import { Switch } from "react-router-dom"
import LoginPage from "./pages/LoginPage.js"
import UserPage from "./pages/UsersPage.js"
import NewUser from "./pages/NewUser.js"
import EditUser from "./pages/EditUser"
import ViewUser from "./pages/ViewUser"
import NewProfile from "./pages/NewProfile.js"
import EditProfile from "./pages/EditProfile.js"
import ProfilesPage from "./pages/ProfilesPage.js";
import AudiencePage from "./pages/AudiencePage.js";
import NewAudience from './pages/newAudience.js'
import PrivateRoute from './components/commons/PrivateRoute'
import PublicRoute from './components/commons/PublicRoute'
import AudienceDetails from './pages/AudienceDetails.js'
import TargetAccountFiles from './pages/AudienceManager'
const AppRouter = () => {
  return (
    <div>
      <Switch>

        <PublicRoute exact path="/" component={LoginPage} />

        <PrivateRoute exact path="/users" component={UserPage} />
        <PrivateRoute exact path="/users/new" component={NewUser} />
        <PrivateRoute exact path="/users/:id/edit" component={EditUser} />
        <PrivateRoute exact path="/users/:id/view" component={ViewUser} />

        <PrivateRoute exact path="/profiles" component={ProfilesPage} />
        <PrivateRoute exact path="/profiles/new" component={NewProfile} />
        <PrivateRoute exact path="/profiles/:id/edit" component={EditProfile} />

        <PrivateRoute exact path="/audiences" component={AudiencePage} />
        <PrivateRoute exact path="/audiences/new" component={NewAudience} />
        <PrivateRoute path="/audiences/:id" component={AudienceDetails} />

        <PrivateRoute exact path="/audience_managers" component={TargetAccountFiles} />
        <PrivateRoute exact path="/audience_managers/new" component={NewAudience} />
        <PrivateRoute path="/audience_managers/:id" component={AudienceDetails} />

      </Switch>
    </div>
  )
}

export default AppRouter