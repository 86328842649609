import React, { useState, useEffect } from 'react';
import {
  Button,
  Spin
} from 'antd';
import { Redirect, useParams } from 'react-router-dom'
import { TextField, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import UserService from '../../services/user'
const userService = new UserService();

const UserForm = ({ isAddMode = true, isViewMode = false, isEditMode = false }) => {
  const { id } = useParams();
  const [btnLoading, setBtnLoading] = useState(false)
  const [pageRedirect, setPageRedirect] = useState(false);
  const [isProcessing, setProcessing] = useState(id ? true : false)
  const [state, setState] = useState({
    role: ''
  })
  const { control, handleSubmit, setValue } = useForm();
  const fetchUser = async () => {
    if (id) {
      let userData = await userService.get(id)
      setValue('firstName', userData.firstName)
      setValue('lastName', userData.lastName)
      setValue('email', userData.email)
      setState({ 'role': userData.role.name })
      setProcessing(false)
    }
  }
  useEffect(() => {
    fetchUser()
  }, [id])

  const onFinish = async (formValues) => {
    setBtnLoading(true)
    setProcessing(true)
    formValues = { ...formValues, ...state }
    formValues.role = { name: formValues.role }
    try {
      await (isEditMode ? userService.update(id, formValues) : userService.create(formValues))
      setPageRedirect(true)
    } catch (error) {
      console.log(error)
    }
    setProcessing(false)
    setBtnLoading(false);


  };

  return (
    <>
      <Spin spinning={isProcessing}>

        {pageRedirect && <Redirect to="/users" />}
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onFinish)}>
          <Controller as={
            <TextField label="First Name"
              InputProps={{
                readOnly: isViewMode,
              }}
              defaultValue=""
              className='mt-3 mb-2'
              fullWidth
              variant="outlined" />

          }
            name='firstName'
            control={control}
            defaultValue=""
          />
          <Controller as={
            <TextField label="Last Name"
              InputProps={{
                readOnly: isViewMode,
              }}
              defaultValue=""
              className='mt-3 mb-2'
              fullWidth
              variant="outlined" />

          }

            control={control}
            name='lastName'
            defaultValue=""
          />
          <Controller as={
            <TextField label="Email"
              InputProps={{
                readOnly: (isViewMode || isEditMode),
              }}
              defaultValue=""
              className='mt-3 mb-2'
              fullWidth
              variant="outlined" />

          }

            control={control}
            name='email'
            defaultValue=""
          />





          {
            isAddMode ?
              <Controller as={
                <TextField label="Password"
                  InputProps={{
                    readOnly: (isViewMode || isEditMode),
                  }}
                  type="password"
                  defaultValue=""
                  className='mt-3 mb-2'
                  fullWidth
                  variant="outlined" />

              }

                control={control}
                name='password'
                defaultValue=""
              />
              : ''
          }



          <FormControl variant="outlined" fullWidth className='mt-3 mb-2' >
            <InputLabel >User Role</InputLabel>
            <Select
              labelWidth={70}
              name="role"
              value={state.role}
              onChange={(event) => {
                setState({ 'role': event.target.value });
              }}
              InputProps={{
                readOnly: isViewMode,
              }}
            >

              <MenuItem value="user">User</MenuItem>
              <MenuItem value="administrator">Administrator</MenuItem>

            </Select>
          </FormControl>
          <div className="float-right mt-2">
            {
              !isViewMode ?
                <Button type="primary" loading={btnLoading}

                  htmlType="submit" style={{ width: '100px' }}>
                  {isAddMode ? 'Save' : 'Update'}
                </Button>
                :
                ''
            }
          </div>


        </form>
      </Spin>

    </>
  );
};



export default (UserForm);