export const getUser = (search) => ({
  type: 'GET_USERS',
  query: search
});

export const getProfiles = (token, query) => ({
  type: 'GET_PROFILES',
  token: token,
  query: query
});

export const getAudiencesDetails = (id, token, filters, page, pageSize) => ({
  type: 'GET_AUDIENCE_DETAILS',
  token: token,
  id: id,
  filters: filters,
  page: page,
  pageSize: pageSize
});

export const getAudiencesFilters = (id, token) => ({
  type: 'GET_AUDIENCE_FILTER_VALUES',
  token: token,
  id: id
})

export const getAudiences = (token, query) => ({
  type: 'GET_AUDIENCES',
  token: token,
  query: query
})

export const logout = () => ({
  type: 'LOGOUT',
})

export const loginUser = ({ email, password }) => ({
  type: 'LOGIN_USER',
  email,
  password
})
export const getLogInUser = () => ({
  type: 'GET_CURRENTUSER',

})

export const addUser = ({ firstName, lastName, email, role, password }) => ({
  type: 'CREATE_USER',
  firstName,
  lastName,
  email: email,
  password,
  role: role
})

export const addAudience = (payload, token) => ({
  type: 'CREATE_AUDIENCE',
  payload: payload,
  token: token
})

export const deleteAudience = (id, token) => ({
  type: 'DELETE_AUDIENCE',
  id: id,
  token: token
})
export const refreshAudience = (id, token) => ({
  type: 'REFRESH_AUDIENCE',
  id: id,
  token: token
})
export const ShowCampaignModal = (id)=>({
  type: 'SHOW_CAMPAIGN_MODAL',
  id: id,
  
})
export const CampaignAdded = ()=>({
  type: 'CAMPAIGN_ADDED',
})
export const hideCampaignModal = (id)=>({
  type: 'HIDE_CAMPAIGN_MODAL',
  id: id,
})

export const deleteUser = (id, token) => ({
  type: 'DELETE_USER',
  id: id,
  token: token
})

export const deleteProfile = (id, token) => ({
  type: 'DELETE_PROFILE',
  id: id,
  token: token
})
export const deleteAudienceManager = (id, token) => ({
  type: 'DELETE_AUDIENCE_MANAGER',
  id: id,
  token: token
})

export const addProfile = ({ name, topics, keywords, createdBy }, token) => ({
  type: 'CREATE_PROFILE',
  name: name,
  topics: topics,
  keywords: keywords,
  createdBy: createdBy,
  token: token
})
export const updateProfile = (payload) => ({
  type: 'UPDATE_PROFILE',
  ...payload

})
export const getProfile = (id) => ({
  type: 'GET_PROFILE',
  id

})
export const checkProfileName = (name) => ({
  type: 'CHECK_PROFILE_NAME',
  name

})