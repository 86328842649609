import React from 'react';
import { reduxForm } from 'redux-form';
import {
  Button,
} from 'antd';
import CustomSelect from '../commons/form/select'
import { Link } from 'react-router-dom'


const AudienceEngAndIntent = props => {
  const { handleSubmit, setValues } = props;

  const submitForm = () => {
    handleSubmit()
  }
  const saveValue = (key, value) => {
    setValues({ [key]: value })
  }
  const intents = [
    // { name: 'Trending Intent', value: 'trending' },
    { name: 'Any Intent Activity', value: 'any' }]
  return (
    <form autoComplete="off">

      <h6 className='custom-clr'>
        Step 5: Engagement and Intent
           </h6>
      <br></br>
      <div className='w-50'>
        <CustomSelect label='Intent Type' keyName='intentType' action={saveValue} options={intents}></CustomSelect>
      </div>

      <div className="float-right">

        <Button type="primary" onClick={submitForm}>
          Complete Audience
      </Button>
      </div>
      <div className="float-left">
        <Link to={"/audiences"}>
          <Button type="dashed"
            style={{ marginTop: "10px" }}>
            Cancel
        </Button>
        </Link>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(AudienceEngAndIntent);
