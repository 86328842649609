export const employeeMarks = {
    0: 0,
    1: 1,
    2: 5,
    3: 10,
    4: 20,
    5: 50,
    6: 100,
    7: 250,
    8: 500,
    9: 1000,
    10: 5000,
    11: 10000,
    12: 'unlimited'


};
