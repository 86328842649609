import React from "react";
import { Row, Col } from 'antd';
import 'antd/dist/antd.css';
import './styles.css'
import AudienceForm from '../components/audiences/AudienceForm'
import { connect } from 'react-redux';


const NewAudience = ({ token }) => {
  return (
    <div>

      <div className="site-layout-content">
        <Row  >
          <Col md={{ span: 20, offset: 2 }}>
            <div className='heading custom-clr'>
              Create a new Audience
                    </div>
            <Row >
              <Col span={24}>
                <AudienceForm />
              </Col>
            </Row>
          </Col>
        </Row>

      </div>

    </div>
  )
}

const mapStateToProps = (state) => (
  {
    token: state.token,
  }
);

export default connect(mapStateToProps)(NewAudience);