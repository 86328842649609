import React, { useState, useEffect } from 'react';
import { Spin, Button, Checkbox, Row, Col } from "antd";
import { Link } from 'react-router-dom'

import AudienceManagerService from '../../services/audience_manager';
import MultiSelect from '../commons/form/multiSelect'
const audienceManagerService = new AudienceManagerService();

const AudienceTargetAccount = props => {
    const { continueSubmit, setValues } = props;
    const [pageLoader, setPageLoader] = useState(false)
    const [state, setState] = useState({
        isSuppress: false,
        targetAccounts: []
    });
    const [targetAccountDataSet, setTargetAccountDataSet] = useState({});

    useEffect(() => {
        async function getFiles() {
            setPageLoader(true);
            try {
                const data = await audienceManagerService.getAll();
                const targetAccountFileObj = {};
                data.forEach(item => {
                    targetAccountFileObj[item.fileName] = item._id
                })
                setTargetAccountDataSet(targetAccountFileObj)
            } catch (error) {

            }
            setPageLoader(false);
        }
        getFiles();
    }, [])
    const saveTargetAccount = (key, value) => {
        setState({
            ...state,
            [key]: value
        });
    }
    const onFinish = () => {
        if (state.targetAccounts && state.targetAccounts.length) {
            setValues({
                targetAccounts: {
                    isSuppressed: state.isSuppress,
                    audienceManagerId: state.targetAccounts.map(ta => targetAccountDataSet[ta])
                }
            })
        }
        continueSubmit()
    }

    return (
        <>
            <h6 className='custom-clr'>
                Step 4: Choose Target Accounts
            </h6>



            <br></br>

            <Spin spinning={pageLoader}>
                <Row>
                    <Col span={14}>
                        <MultiSelect
                            label='Select Target Account List'
                            variant='outlined'
                            keyName='targetAccounts'
                            action={saveTargetAccount}
                            multiple={true}
                            labelWidth={10}
                            className='mb-4'
                            value={state.targetAccounts || []}
                            options={Object.keys(targetAccountDataSet) || []}
                        />
                        <div className='mt-2'>
                            <Checkbox onChange={(event) => {
                                setState({ ...state, isSuppress: event.target.checked })
                            }}><b>Do you want to suppress these accounts?</b></Checkbox>
                        </div>

                    </Col>

                </Row>
                <br></br>


            </Spin>

            <div className="float-right">
                <Button type="primary" onClick={onFinish}
                    style={{ marginTop: "10px" }}>
                    Continue
      </Button>
            </div>
            <div className="float-left">
                <Link to={"/audiences"}>
                    <Button type="dashed"
                        style={{ marginTop: "10px" }}>
                        Cancel
        </Button>
                </Link>
            </div>
        </>
    );
};

export default (AudienceTargetAccount);
