import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Divider, Row, Col, Button } from 'antd';
import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import { getAudiencesFilters } from '../../actions'
import { CaretRightOutlined, CaretDownOutlined } from "@ant-design/icons";
import CustomSelect from '../commons/form/select'
import MultiSelect from '../commons/form/multiSelect'
import RevenueSlider from '../commons/RevenueSlider'
import EmployeeSlider from '../commons/EmployeeSlider'
import AudienceService from '../../services/audience';
import { DefaultFilter } from '../../utils/filter'
const audienceService = new AudienceService();
const AudienceDetailSideBar = ({ token, getAudiencesFilters,
  audienceFilters, applyFilter }) => {
  const { id } = useParams();
  const [filters, setFilters] = useState(DefaultFilter)
  const [domain, setDomain] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showHide, setShowHide] = useState({});
  const [refresh, setReresh] = useState(1);
  const applyBtn = React.createRef();
  useEffect(() => {
    getAudiencesFilters(id, token)
  }, [id]);

  const getDomains = async () => {
    setLoading(true)
    let domains = await audienceService.getDomains(id, domain);
    audienceFilters.domains = domains;
    setLoading(false)
  }
  useEffect(() => {

    domain && getDomains()

  }, [domain])
  useEffect(() => {

    refresh !== 1 && applyFilter(filters)

  }, [refresh])

  const saveValue = (key, value) => {
    setFilters({
      ...filters, [key]: value.length ? value : []
    });


  }
  useEffect(() => {
    setFilters({
      ...filters, 'overallscores': 'Tier 1'
    })
  }, [])

  return (
    <div className='sidebarFilter'>
      <div style={{ marginTop: '11px' }} className='mt-5 ' >
        <MultiSelect
          label='Search by Account' variant='outlined'
          keyName='domain'
          action={saveValue}
          value={filters.domain}
          loading={loading}
          selectedText={setDomain}
          multiple={true}
          labelWidth={140}
          isAsync={true}
          options={audienceFilters.domains || []}
        />
      </div>
      
      <Divider />

      <MultiSelect
        label='Search by Topic'
        variant='outlined'
        keyName='totaltopiclist'
        action={saveValue}
        value={filters.totaltopiclist}
        multiple={true}
        labelWidth={120}
        className='mb-4'
        isAsync={false}
        options={audienceFilters.topics || []}
      />
      <MultiSelect
        label='Search by Industry'
        variant='outlined'
        keyName='industry'
        action={saveValue}
        value={filters.industries}
        multiple={true}
        labelWidth={120}
        className='mb-4'
        isAsync={false}
        options={audienceFilters.industries || []}
      />

      <CustomSelect label='Search by Account Type' variant='outlined'
        keyName='overallscores'
        action={saveValue}
        labelWidth={190}
        className='mb-3'
        value={filters.overallscores}
        options={audienceFilters.accountTypes || ['Tier 1', 'Tier 2',
          'Total']} />
      <div className='mb-3 mt-2'>
        <span className='sidebar-icon'>
          {
            showHide.revenue ?
              <CaretDownOutlined onClick={() => { setShowHide({ ...showHide, revenue: false }) }} />
              :
              <CaretRightOutlined onClick={() => { setShowHide({ ...showHide, revenue: true }) }} />


          }


        </span>
        <span className='sidebar-ico-text '>
          Revenue Range ($ USD)
      </span>
        <div className='my-4' style={{ display: showHide.revenue ? 'block' : 'none' }}>
          <RevenueSlider setValue={saveValue} values={filters} />
        </div >

      </div>
      <div className='mb-3'>
        <span className='sidebar-icon'>
          {
            showHide.employee ?
              <CaretDownOutlined onClick={() => { setShowHide({ ...showHide, employee: false }) }} />
              : <CaretRightOutlined onClick={() => { setShowHide({ ...showHide, employee: true }) }} />

          }

        </span>
        <span className='sidebar-ico-text '>
          Number of Employees
       </span>
        <div className='my-4' style={{ display: showHide.employee ? 'block' : 'none' }}>
          <EmployeeSlider setValue={saveValue} values={filters}
            defaultValue={[audienceFilters?.employee?.min || 0, audienceFilters?.employee?.max || 12]} />
        </div>
      </div>
      <Divider />

      <Row gutter={16} className="mt-4">
        <Col span={12}>

          <Button className='w-100'
            onClick={() => {
              setFilters(DefaultFilter)
              setReresh(refresh + 1);


            }}
          >Clear</Button>
        </Col>
        <Col span={12}>
          <Button type="primary" className="w-100"
            ref={applyBtn}
            onClick={() => {
              applyFilter(filters);

            }}
          >Apply</Button>
        </Col>
      </Row>
      {/* <div className='mb-3'>
                        <span className='sidebar-icon'>
                          <CaretRightOutlined />
                        </span>
                        <span className='sidebar-ico-text '>
                         Overall Score
                        </span>
                      </div> */}
      {/* { audienceFilters.rank && 
                        (<div>
                          <Label>Rank</Label>
                          <Slider range defaultValue={[audienceFilters.rank.min, audienceFilters.rank.max]} min={audienceFilters.rank.min} max={audienceFilters.rank.max} 
                          onChange={setRankRange}/>
                        </div>)
                      } 
                      <div>
                        <Label>Revenue</Label>
                        <Slider range defaultValue={[1, 100]} marks={marks} step={null} onChange={setRevenueRange}/>
                      </div> */}
    </div>
  )
}
const mapDispatchToProps = {

  getAudiencesFilters: getAudiencesFilters,
};

const mapStateToProps = (state) => (
  {
    token: state.token,
    audienceFilters: state.audienceFilters,
    error: state.error,

  }
);

export default connect(mapStateToProps, mapDispatchToProps)(AudienceDetailSideBar);