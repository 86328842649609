import React from 'react';
import { reduxForm } from 'redux-form';
import {
  Button,
} from 'antd';
import { TextField } from '@material-ui/core';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom'
const AudienceFormName = props => {
  const { continueSubmit, setValues } = props;
  const { register, handleSubmit, formState } = useForm({
    mode: "onChange"
  });

  const onFinish = (value) => {
    setValues(value);
    continueSubmit()
  }

  return (
    <>
      <h6 className='custom-clr'>
        Step 1: Name Audience
           </h6>

      <small className='d-block'>Enter a short label for your profile corresponding to your usecase or product category name that you are marketing</small>

      <br></br>
      <form onSubmit={handleSubmit(onFinish)}>
        <div className="w-50">
          <TextField label="Audience Name" inputRef={register({ required: true })}
            className='my-3  d-block' name='name' fullWidth variant="outlined" />
        </div>
        <div className="float-right">
          <Button type="primary" htmlType="submit"
            disabled={!formState.isValid}
          >
            Continue
      </Button>
        </div>
        <div className="float-left">
          <Link to={"/audiences"}>
            <Button type="dashed"
              style={{ marginTop: "10px" }}>
              Cancel
        </Button>
          </Link>
        </div>
      </form>
    </>

  );
};

export default reduxForm({
  form: 'audienceFormName', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(AudienceFormName);
