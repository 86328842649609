
import React, { Component } from 'react';
import AudienceFormName from './AudienceFormName';
import AudienceFormProfiles from './AudienceFormProfiles'
import AudienceFormFirmographics from './AudienceFormFirmographics'

import AudienceEngAndIntent from './AudienceEngAndIntent'
import AudienceTargetAccount from './audienceTargetAccount'
import { getProfiles, addAudience } from '../../actions';
import { Redirect } from 'react-router-dom'

import {
  Row,
  Col,
  Card
} from 'antd';
import { connect } from 'react-redux';

import { Steps } from 'antd';
const { Step } = Steps;


class AudienceForm extends Component {
  constructor(props) {
    super(props);
    this.props.getProfiles(this.props.token)
    this.state = {
      page: 0,
      values: [],
      disableAll: false
    };
  }
  nextPage = () => {
    this.setState({ page: this.state.page + 1 });

  }
  backPage = () => {
    this.setState({ page: this.state.page - 1 });

  }


  setValues = (values) => {

    this.setState({ values: { ...this.state.values, ...values } })
  }

  submitForm = async () => {
    this.props.addAudience(this.state.values, this.props.token)
  }

  render() {

    const { page } = this.state;
    return (
      <div>
        {this.props.success && <Redirect to="/audiences" />}
        <Row>
          <Col span={24}>
            <Card className="step-card step-card-aud step-card-aud-head">
              <Steps
                current={page}


                size="small" labelPlacement={"vertical"}>
                <Step title="Name Your Audience" />
                <Step title="Chose Profile" />
                <Step title="Firmographics" />
                <Step title="Target Account List (TAL)" />
                {/* <Step title="Persona" /> */}
                <Step title="Engagement & Intent Step" />
              </Steps>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card className="step-card step-card-aud mt-3">
              {page === 0 && <AudienceFormName
                setValues={this.setValues}
                continueSubmit={this.nextPage}
              />}

              {page === 1 && <AudienceFormProfiles
                setValues={this.setValues}
                handleSubmit={this.nextPage}
                backPage={this.backPage}
                profileColumns={this.props.profileColumns}
                profiles={this.props.profiles} />}
              {page === 2 && <AudienceFormFirmographics
                setValues={this.setValues}
                handleSubmit={this.nextPage}
                backPage={this.backPage}
              />}
              {page === 3 && <AudienceTargetAccount
                setValues={this.setValues}
                continueSubmit={this.nextPage}
                backPage={this.backPage}
              />}
              {/* {page === 3 && <AudienceFormPersona    backPage={this.backPage} setValues={this.setValues} handleSubmit={this.nextPage} />} */}
              {page === 4 && <AudienceEngAndIntent
                setValues={this.setValues}
                backPage={this.backPage}
                handleSubmit={this.submitForm}
              />}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getProfiles: getProfiles,
  addAudience: addAudience,
};

const mapStateToProps = (state) => (
  {
    loading: state.loading,
    success: state.success,
    token: state.token,
    profiles: state.profiles,
    profileColumns: state.profileColumns,
    error: state.error
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(AudienceForm);