import React, { useEffect } from 'react'
import { Spin, Table } from 'antd';
import { connect } from 'react-redux';

function UsersTable({ loading, userColumns, users }) {
  useEffect(() => {
  }, [users, userColumns])
  return (
    <>
      <Spin spinning={loading}>
        <div>
          <Table bordered={true}
            columns={userColumns}
            style={{ boxShadow: '0 7px 13px 0 rgba(0, 0, 0, 0.06)' }}
            pagination={{ size: "small" }}
            dataSource={users || null}
          />
        </div>

      </Spin>
    </>
  )
}

const mapStateToProps = (state) => (
  {
    loading: state.loading,
    users: state.users,
    userColumns: state.userColumns
  }
);

export default connect(mapStateToProps)(UsersTable);