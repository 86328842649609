
import React from 'react'
import { connect } from 'react-redux';
import { logout } from '../../actions'
import { Menu } from 'antd';
import { SettingOutlined, LogoutOutlined } from "@ant-design/icons";
const { SubMenu } = Menu;

function AppHeader({ loading, token, currentUser, logout }) {

  return (

    <Menu mode="horizontal" className={'shadow-sm main-nav'}>
      <Menu.Item key="home">


        <a href='/'>  <img src="https://pureb2b.com/wp-content/uploads/2019/06/image-2-3.png"
          alt="PureB2B" className="main-logo" width="100"></img></a>

      </Menu.Item>
      {token && token !== "undefined" && <>
        <SubMenu key="SubMenu" icon={<SettingOutlined />} title={currentUser.email || "User"}>
          <Menu.Item key="logout:2" icon={<LogoutOutlined />} onClick={() => logout()}>Logout</Menu.Item>
        </SubMenu>
      </>
      }
    </Menu>

  )
}


const mapDispatchToProps = {
  logout: logout,
};

const mapStateToProps = (state) => (
  {
    loading: state.loading,
    token: state.token,
    currentUser: state.logInUser,

  }
);

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);