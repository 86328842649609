import axios from "../axios-configured";
class CrudService {
    constructor(subUrl) {
        this.subUrl = subUrl
    }
    async update(id, payload) {

        return axios.put(`${this.subUrl}/${id}`, payload);
    }
    async get(id, params = {}) {
        return axios.get(`${this.subUrl}/${id}`, { params: { filters: params } });
    }
    async getAll(params = {}) {
        return axios.get(`${this.subUrl}`, { params: { filters: params } });
    }
    async create(payload) {

        return axios.post(`${this.subUrl}`, payload);
    }
    async uploadFile(payload) {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        return axios.post(`${this.subUrl}`, payload, config);
    }
    async delete(id) {
        return axios.delete(`${this.subUrl}/${id}`);
    }
}
export default CrudService