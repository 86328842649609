import React, { useEffect } from 'react'
import { Spin, Table, Pagination } from 'antd';
import { connect } from 'react-redux';

function AudienceDetailsTable({ loading, audienceDetails, audienceDetailsColumns, onPaginationChange, pagination }) {

  useEffect(() => {
    console.log(audienceDetails)
  }, [audienceDetails, audienceDetailsColumns])
  return (
    <>
      <Spin spinning={loading}>
        <div className='row-spacer audienceDetailtable'>
          <Table
            columns={audienceDetailsColumns}
            dataSource={audienceDetails.rows || null}
            pagination={false}

          />
        </div>

        <div className=" py-2" style={{ textAlign: 'end' }}>
          <Pagination
            disabled={loading}
            defaultCurrent={pagination.currentPage}
            current={pagination.currentPage}
            total={audienceDetails.count}
            pageSize={10}
            size="small"
            onChange={(page) => {
              onPaginationChange(page);
            }}
            showSizeChanger={false}

          />
        </div>
      </Spin>
    </>
  )
}

const mapStateToProps = (state) => (
  {
    loading: state.loading,
    audienceDetails: state.audienceDetails,
    audienceDetailsColumns: state.audienceDetailsColumns
  }
);

export default connect(mapStateToProps)(AudienceDetailsTable);