import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "./AppRouter"
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactQueryDevtools } from 'react-query-devtools'
import { ReactQueryCacheProvider, QueryCache } from 'react-query'
const queryCache = new QueryCache()

function App() {

  return (
    <>
      <ReactQueryDevtools initialIsOpen={false} />

      <ReactQueryCacheProvider queryCache={queryCache}>
        <Router>
          <AppRouter />
        </Router>
      </ReactQueryCacheProvider>
    </>

  );
}

export default App;
