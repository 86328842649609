import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Button } from 'antd';
import 'antd/dist/antd.css';
import './styles.css'
import { useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { getAudiencesDetails } from '../actions'
import AudienceDetailsTable from "../components/audiences/AudienceDetailsTable";
// import { downloadFileUrl } from '../api/index'
import AudienceDetailSideBar from '../components/audiences/audienceDetailSidebar'
import { convertFilterIntoQuery } from '../utils/filter'

const AudienceDetails = ({ token, error, audienceDetails, getAudiencesDetails, audienceFilters }) => {

  const { id } = useParams();
  const [filters, setFilters] = useState({
    overallscores: 'Tier 1'
  })
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 20,
  });
  // const downloadFile = async () => {
  //   let { url } = await downloadFileUrl(id);
  //   window.open(url, "Download");
  // };
  const updateFilter = (key, value) => {
    setFilters({ ...filters, [key]: value })
  }
  const applyFilter = (filterState) => {
    setFilters({ ...filters, ...filterState })
    setPagination({ ...pagination, currentPage: 1 })
  }
  useEffect(() => {
    getAudiencesDetails(id, token, filters, pagination.currentPage, pagination.pageSize)
  }, [id, pagination])
  const downloadUrl = `${process.env.REACT_APP_API_BASE_URL}/audiences/${id}/export?token=${token}&filters=`;
  const handlePagination = async (pagination) => {
    await setPagination({ currentPage: pagination });
  };
  return (
    <div className="site-layout-content audience">
      {error &&
        (<Alert
          message="There was an error while processing your request. Try again later!"
          type="error"
          closable
        />)}
      <Row  >
        <Col md={{ span: 22, offset: 1 }}>
          <Row>
            <Col span={6} style={{ paddingRight: '10px' }} >
              <AudienceDetailSideBar
                updateFilter={updateFilter} applyFilter={applyFilter} />
            </Col>
            <Col span={18} className='px-3' >
              <Row >
                <Col md={{ span: 18 }}>
                  <div className='heading custom-clr text-capitalize'>
                    {
                      audienceFilters?.name
                    }

                  </div>
                  {
                    audienceFilters ? <>
                      <span className='font-size-12' style={{ display: 'block' }} >
                        <b>Created:</b>
                        {audienceFilters.createdAt ?
                          <>
                            <span>
                              {
                                new Date(audienceFilters.createdAt).toLocaleString()
                              } by  </span>
                            <a href='#'> {audienceFilters?.createdBy?.email} </a>
                          </>
                          : 'loading...'}
                      </span>

                      <span className='font-size-12' ><b>Date Refreshed:</b> {audienceFilters.completedAt ? new Date(audienceFilters.completedAt).toLocaleString() : ''}</span>
                      <span className="mx-2 font-size-12"> <b>|</b></span>
                      <span className='font-size-12'  ><b>Count:</b> {audienceDetails?.count?.toLocaleString()} </span>
                    </> : ''
                  }
                </Col>
                <Col md={{ span: 6 }} style={{ display: "flex", justifyContent: 'flex-end', alignItems: "center" }}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${downloadUrl}${convertFilterIntoQuery(filters)

                      }`}
                  >
                    <Button type="primary"

                      shape="round"
                      size={'large'}
                      className='downloadCsv'
                    >Export to CSV</Button>
                  </a>

                </Col>
              </Row>
              <Row className='my-3' >
                <Col md={{ span: 24 }}>

                  <AudienceDetailsTable onPaginationChange={handlePagination}
                    pagination={pagination} />
                </Col>
              </Row>
            </Col>
          </Row>

        </Col>
      </Row>
    </div >
  )
}

const mapDispatchToProps = {
  getAudiencesDetails: getAudiencesDetails,
};

const mapStateToProps = (state) => (
  {
    token: state.token,
    audienceFilters: state.audienceFilters,
    error: state.error,
    audienceDetails: state.audienceDetails,

  }
);

export default connect(mapStateToProps, mapDispatchToProps)(AudienceDetails);