import React, { useEffect, useState } from 'react'
import { Spin, Table } from 'antd';
import { useSelector } from 'react-redux';

import AudienceManagerService from '../../services/audience_manager';
const audienceManagerService = new AudienceManagerService();
function TargetAccountTable({ searchKey, pageRefresh, ...props }) {
  const { loading, audienceManagerColumns, refresh } = useSelector(state => state);
  const [pageLoader, setPageLoader] = useState(loading)
  const [targetFiles, setTargetFiles] = useState([]);
  useEffect(() => {
    async function getFiles() {
      setPageLoader(true);
      try {
        const data = await audienceManagerService.getAll(searchKey);
        setTargetFiles(data)
      } catch (error) {

      }
      setPageLoader(false);
    }
    getFiles();
  }, [searchKey, refresh, pageRefresh])
  return (
    <>
      <Spin spinning={pageLoader}>
        <Table className={'profileTable'}
          bordered={true}
          style={{ boxShadow: '0 7px 13px 0 rgba(0, 0, 0, 0.06)' }}
          columns={audienceManagerColumns}
          dataSource={targetFiles || null}
          pagination={{ size: "small" }}
        />
      </Spin>

    </>
  )
}


export default TargetAccountTable;