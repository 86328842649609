import React, { useState } from "react";
import { reduxForm } from "redux-form";
import { Button } from "antd";
import { Col, Row, Card, TreeSelect, Checkbox } from "antd";

import { locations,regions,locationHash } from "../../data/locations";
import { INDUSTRIES, IndustriesTopLevel } from "../../data/pureindustries";
import { Link } from "react-router-dom";
import useMultiSelectCheckbox from "../../hooks/useMultiSelectCheckbox";

import { RenvueList, EmployeeList, findMinMax } from "../../data/revenue";
import { Box } from "@material-ui/core";

const initialEmployeeList = EmployeeList.map((employee) => ({
  ...employee,
  isChecked: false,
}));

const initialRevenueList = RenvueList.map((revenue) => ({
  ...revenue,
  isChecked: false,
}));

const AudienceFormFirmographics = (props) => {
  const [employeeList, setEmployeeList] = React.useState(initialEmployeeList);
  const [revenueList, setRevenueList] = React.useState(initialRevenueList);
  const { handleSubmit, setValues } = props;
  const [selectedState, setSelectedState] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedHQLocation,setHQLocation] = useState(true)

  const employeeChangeHandler = useMultiSelectCheckbox(
    employeeList,
    setEmployeeList,
    initialEmployeeList
  );
  const revenueChangeHandler = useMultiSelectCheckbox(
    revenueList,
    setRevenueList,
    initialRevenueList
  );

  const onFinish = (values) => {

    const employee = employeeList.filter((item) => item.isChecked === true)
      .length
      ? findMinMax(employeeList.filter((item) => item.isChecked === true))
      : undefined;
    const revenue = revenueList.filter((item) => item.isChecked === true).length
      ? findMinMax(revenueList.filter((item) => item.isChecked === true))
      : undefined;

    let nestedIndustries = []
    selectedIndustry.forEach((ele) =>
      INDUSTRIES.find((i) => i.key === ele).children.map((c) => nestedIndustries.push(c.key))
    );
    const firmographics = {
      employee,
      revenue,
      location: selectedState,
      regions:selectedRegion,
      isHQLocation:selectedHQLocation,
      industries: selectedIndustry,
      nestedIndustries: nestedIndustries,
    };
    setValues({
      firmographics,
    });
    handleSubmit();
  };
  return (
    <>
      <form name="dynamic_form_nest_item" autoComplete="off">
        <Box display="flex">
          <h6 className="custom-clr" display="inline-block">
            Step 3: Firmographics
          </h6>
          <h6 className="custom-clr" style={{ marginLeft: "auto" }}>
            No Selection in a given firmographic will result with all domains
            being selected
          </h6>
        </Box>
        <br></br>
        <Row className="row-start border-tb firmograph">
          <Col span={12} style={{ borderRight: "1px solid #f0f0f0" }}>
            <Card title="Account Location" className="border-0">
              <TreeSelect
                className="mt-3 mb-1"
                {...{
                  treeData: locations,
                  allowClear: true,
                  value: selectedState,
                  onChange: (checkedKeys, label, extra) => {
                   
                    setSelectedState(checkedKeys);
                  },
                 
                  multiple: true,
                  treeCheckable: true,
                  showCheckedStrategy: "SHOW_PARENT",
                  placeholder: "Select State",
                  style: {
                    width: "100%",
                  },
                }}
              />
              <TreeSelect
                className="mt-3 mb-1"
                {...{
                  treeData: regions,
                  allowClear: true,
                  value: selectedRegion,
                  onChange: (checkedKeys, label, extra) => {
                    setSelectedRegion(checkedKeys);
                  },
                  multiple: true,
                  autoClearSearchValue: false,
                  treeCheckable: true,
                  placeholder: "Select Region",
                  style: {
                    width: "100%",
                  },
                }}
              />
              <div className='mt-3'>
              <Checkbox
                      checked={selectedHQLocation}
                      onChange={(event) => {
                        setHQLocation(event.target.checked)
                      }}
                      >
                        <b>HQ Location</b>
                      </Checkbox>
                      </div>
            </Card>
          </Col>

          <Col span={12}>
            <Card title="Industry" className="border-0 ">
              <TreeSelect
                className="mt-3 mb-1"
                {...{
                  treeData: IndustriesTopLevel,
                  allowClear: true,
                  autoClearSearchValue: false,
                  value: selectedIndustry,
                  onChange: (checkedKeys, label, extra) => {
                    setSelectedIndustry(checkedKeys);
                  },
                  multiple: true,
                  treeCheckable: true,
                  showCheckedStrategy: "SHOW_PARENT",
                  placeholder: "Select Industry",
                  style: {
                    width: "100%",
                  },
                }}
              />
            </Card>
          </Col>
        </Row>

        <Row className=" row-start border-tb firmograph">
          <Col
            span={12}
            style={{ borderRight: "1px solid #f0f0f0", paddingRight: "5px" }}
          >
            <Card title="Employee size" className="border-0 ">
              {employeeList.map((employee, index) => {
                return (
                  <>
                    <Checkbox
                      checked={employee.isChecked}
                      onChange={(event) => {
                        employeeChangeHandler(
                          employee.value,
                          event.target.checked,
                          index
                        );
                      }}
                    >
                      {employee.label}
                    </Checkbox>
                    <br />
                  </>
                );
              })}
            </Card>
          </Col>
          <Col span={12} id="aud-revenue">
            <Card title="Revenue ($USD)" className="border-0 ">
              {revenueList.map((revenue, index) => {
                return (
                  <>
                    <Checkbox
                      checked={revenue.isChecked}
                      onChange={(event) => {
                        revenueChangeHandler(
                          revenue.value,
                          event.target.checked,
                          index
                        );
                      }}
                    >
                      {revenue.label}
                    </Checkbox>
                    <br />
                  </>
                );
              })}
            </Card>
          </Col>
        </Row>
        <div className="float-right">
          <Button
            type="primary"
            onClick={onFinish}
            style={{ marginTop: "10px" }}
          >
            Continue
          </Button>
        </div>
        <div className="float-left">
          <Link to={"/audiences"}>
            <Button type="dashed" style={{ marginTop: "10px" }}>
              Cancel
            </Button>
          </Link>
        </div>
      </form>
    </>
  );
};

export default reduxForm({
  form: "wizard", //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(AudienceFormFirmographics);
