import React, { useCallback } from "react";
import { TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";
const SearchBar = ({ onChange, setValue = (val) => {}, value, ...rest }) => {
	const debounce = useCallback(
		_.debounce((_searchVal) => {
			onChange({ [rest.name]: _searchVal.length ? _searchVal : undefined });
		}, 1000),
		[]
	);
	return (
		<TextField
			{...rest}
			onChange={(e) => {
				setValue(e.target.value);
				debounce(e.target.value);
			}}
			InputProps={{
				endAdornment: <SearchIcon style={{ color: "rgba(0, 0, 0, 0.38)" }} />,
			}}
		/>
	);
};
export default SearchBar;
