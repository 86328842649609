
import CrudService from './crud'
class AudienceManagerService extends CrudService {
    constructor() {
        const subUrl = '/audience_managers'
        super(subUrl)
        this.subUrl = subUrl
    }


}
export default AudienceManagerService
