const baseUrl = process.env.REACT_APP_API_BASE_URL
async function postMethod(url = '', data = {}, token = '') {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": window.localStorage.token
    },
    body: data
  });
  return response.json();
}
async function putMethod(url = '', data = {}) {
  
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      "Content-Type": "application/json",
      "Authorization": window.localStorage.token
    },
    body: data
  });
  
  return response.json();
}

async function getMethod(url = '', token = '') {
  const response = await fetch(url, {
    method: 'get',
    headers: {
      "Content-Type": "application/json",
      "Authorization": window.localStorage.token
    },
  }).then((response) => {
    if (response.status >= 400 && response.status < 600) {
      throw new Error("Bad response from server");
    }
    return response;
  })
  return response.json();
}

async function deleteMethod(url = '', token = '') {
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      "Content-Type": "application/json",
      "Authorization": window.localStorage.token
    },
  }).then((response) => {
    if (response.status >= 400 && response.status < 600) {
      throw new Error("Bad response from server");
    }
    return response;
  })
  return response.json();
}

export const getUsersFromServer = (query) => {
  return getMethod(`${baseUrl}/users?filters=${query}`)
}

export const getProfilesFromServer = (token, query) => getMethod(`${baseUrl}/profiles?filters=${query}`, token)

export const getAudiencesFromServer = (token, query) => getMethod(`${baseUrl}/audiences?filters=${query}`, token)

export const getAudiencesDetailsFromServer = (id, token, filters, page, pageSize) => getMethod(`${baseUrl}/audiences/${id}/data?pageNum=${page}&filters=${filters}`, token)

export const deleteAudienceApi = (id, token) => deleteMethod(`${baseUrl}/audiences/${id}`, token)
export const refreshAudienceApi = (id, token) => putMethod(`${baseUrl}/audiences/${id}/refresh`,JSON.stringify({}))

export const deleteProfileApi = (id, token) => deleteMethod(`${baseUrl}/profiles/${id}`, token)
export const deleteAudienceManagerApi = (id, token) => deleteMethod(`${baseUrl}/audience_managers/${id}`, token)

export const deleteUserApi = (id, token) => deleteMethod(`${baseUrl}/users/${id}`, token)

export const getAudienceFilterValues = (id, token) => getMethod(`${baseUrl}/audiences/${id}/filterValues`, token)

export const createUserApi = (payLoad) => {
  return postMethod(`${baseUrl}/users`, payLoad)
}

export const createProfileApi = (payLoad, token) => {
  return postMethod(`${baseUrl}/profiles`, payLoad, token)
}
export const getProfileFromServer = (id) => {
  return getMethod(`${baseUrl}/profiles/${id}`);
}
export const updateProfile = (id, data) => {
  return putMethod(`${baseUrl}/profiles/${id}`, data);
}

export const createAudienceApi = (payLoad, token) => {
  return postMethod(`${baseUrl}/audiences`, payLoad, token)

}

export const serverLogin = (payLoad) => {
  return postMethod(`${baseUrl}/auth`, payLoad)
}
export const downloadFileUrl = (id) => getMethod(`${baseUrl}/audiences/${id}/downloadUrl`)

export const getUser = (id) => {
  return getMethod(`${baseUrl}/users/${id}`);
}
export const getLogInUser = () => {
  return getMethod(`${baseUrl}/users/getCurrentUser`);
}

