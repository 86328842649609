import React, { useEffect, useState } from "react";
import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import { Button, Alert, Row, Col } from 'antd';
import { getUser } from '../actions';
import UsersTable from '../components/users/UsersTable'
import { Link } from 'react-router-dom'

import { PlusOutlined } from '@ant-design/icons';

import SearchBar from "../components/commons/form/search";
const UserPage = ({ getUser, error, token, refresh }) => {

  const [searchKey, setSearchKey] = useState('')
  useEffect(() => {
    getUser(searchKey)
  }, [getUser, searchKey, refresh])

  return (
    <div>

      <div className="site-layout-content">

        {error &&
          (<Alert
            message="There was an error while processing your request. Try again later!"
            type="error"
            closable
          />)}
        <Row  >
          <Col md={{ span: 20, offset: 2 }}>
            <div className='heading custom-clr'>
              User for PureB2B
            </div>
            <Row >
              <Col md={{ span: 12 }}>
                <SearchBar label="Search by Name and Email"
                  className='my-3'
                  name='name'
                  fullWidth
                  variant="outlined"
                  onChange={setSearchKey}
                />


              </Col>
              <Col md={{ span: 12 }} style={{ display: "flex", justifyContent: 'flex-end', alignItems: "center" }}>

                <Link to={"/users/new"}>
                  <Button type="primary"
                    shape="round"
                    size={'large'}
                    icon={<PlusOutlined />}
                  >Create New User</Button>
                </Link>
              </Col>
            </Row>
            <Row className='my-3' >
              <Col md={{ span: 24 }}>
                <UsersTable />
              </Col>
            </Row>
          </Col>
        </Row>

      </div>

    </div>
  )
}
const mapDispatchToProps = {
  getUser: getUser,
};

const mapStateToProps = (state) => (
  {
    error: state.error,
    token: state.token,
    refresh: state.refresh
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);

