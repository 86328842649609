import { marks, PriceInThousand } from '../data/revenue'
import { employeeMarks } from '../data/employee'

export const DefaultFilter = {
    revenue: [],
    employee: [],
    domain: [],
    overallscores: '',
    totaltopiclist: [],
    industry: []
}


export const convertFilterIntoQuery = (filter) => {
    let {
        domain, revenue, employee, overallscores, totaltopiclist, industry
    } = filter
    let queryObj = {
        domain: domain && domain.length ? domain : undefined || undefined,
        totaltopiclist: totaltopiclist && totaltopiclist.length ? totaltopiclist : undefined || undefined,
        industry: industry && industry.length ? industry : undefined || undefined,
        'revenue.min': undefined,
        'revenue.max': undefined,
        'employee.min': undefined,
        'employee.max': undefined,

    }
    addEmployeeInQueryObject(employee, queryObj)
    addRevenueInQueryObject(revenue, queryObj)
    let istrending = undefined
    if (overallscores && overallscores.toLowerCase() === 'tier 1') {
        istrending = 1
    }
    else if (overallscores && overallscores.toLowerCase() === 'tier 2') {
        istrending = 0
    }
    queryObj.istrending = istrending;
    console.log('queryObj', queryObj)
    return encodeURIComponent(JSON.stringify(queryObj));



}
const addEmployeeInQueryObject = (employee, queryObject) => {
    if (!employee || !employee.length) {
        return queryObject;
    }
    queryObject['employee.min'] = { $gte: employeeMarks[employee[0]] } || undefined
    if (employeeMarks[employee[1]] !== 'unlimited') {
        queryObject['employee.max'] = { $lte: employeeMarks[employee[1]] } || undefined
    }
}
const addRevenueInQueryObject = (revenue, queryObject) => {
    if (!revenue || !revenue.length) {
        return queryObject;
    }
    queryObject['revenue.min'] = { $gte: PriceInThousand[marks[revenue[0]]] } || undefined
    if (marks[revenue[1]] !== 'unlimited') {
        queryObject['revenue.max'] = { $lte: PriceInThousand[marks[revenue[1]]] } || undefined
    }
}
