import React, { useState } from "react";
import 'antd/dist/antd.css';
import './styles.css'
import { Button, Alert, Row, Col } from 'antd';
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons';
import SearchBar from "../components/commons/form/search";

import ProfileList from '../components/commons/ProfileList'
import { useSelector } from "react-redux";

const ProfilePage = (props) => {

  const [searchKey, setSearchKey] = useState('');
  const state = useSelector((state) => state);
  return (
    <div className="site-layout-content">

      {state.error &&
        (<Alert
          message="There was an error while processing your request. Try again later!"
          type="error"
          closable
        />)}
      <Row  >
        <Col md={{ span: 20, offset: 2 }}>
          <div className='heading custom-clr'>
            Profiles
           </div>

          <Row >
            <Col md={{ span: 12 }}>
              <SearchBar label="Search by Profile Name"
                className='my-3' name='name'
                fullWidth
                onChange={setSearchKey}
                variant="outlined" />

            </Col>
            <Col md={{ span: 12 }} style={{ display: "flex", justifyContent: 'flex-end', alignItems: "center" }}>
              <Link to={"/profiles/new"}>
                <Button type="primary"
                  shape="round"
                  size={'large'}
                  icon={<PlusOutlined />}
                >Create New Profile</Button>
              </Link>
            </Col>
          </Row>
          <Row className='my-3' >
            <Col md={{ span: 24 }}>
              <ProfileList searchKey={searchKey} />
            </Col>
          </Row>
        </Col>
      </Row>

    </div>


  )
}

export default (ProfilePage);

