
import React from 'react'
import { Slider } from 'antd';
import { employeeMarks } from '../../data/employee'

function EmployeeSlider(props) {
    const { setValue, values, defaultValue } = props

    return (
        <React.Fragment className='sliderToolTip'>
            <Slider
                range
                dots={true}
                step={1}
                max={12}
                min={0}
                tipFormatter={(value) => {

                    return employeeMarks[value]
                }
                }
                value={values.employee && values.employee.length ? values.employee : defaultValue}
                defaultValue={defaultValue}
                className='custom-clr'
                onChange={(range) => {
                    setValue('employee', range)
                }
                } />
        </React.Fragment>

    )
}




export default (EmployeeSlider);