import React from "react";
import { Row, Col } from 'antd';
import 'antd/dist/antd.css';
import './styles.css'
import LoginForm from '../components/LoginForm'



const LoginPage = () => {
  return (

    <div className="site-layout-content ">
      <Row justify="center" align="middle" style={{ minHeight: '60vh' }} >
        <Col span={8}>
          <LoginForm />
        </Col>
      </Row>

    </div>


  )
}

export default LoginPage